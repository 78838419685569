import { useEffect, useRef, useState } from "react"
import { Button, Form } from "react-bootstrap"
import PreviewMediaFile from "./PreviewMediaFile"
import { BsImages } from "react-icons/bs"
import { useCommon } from "../hooks/useCommon"

const ImageUploader = ({ setNewFiles, setDeletedExistingFile, deletedExistingFile, existingMediaFiles, clearPreview = false, label = "Upload Photos", multipleUpload = "true", width = "100%", icon = "", previewHeight = 64, previewWidth = 64 }) => {
    const inputFile = useRef(null)
    const [files, setFiles] = useState([]);
    const cm = useCommon();

    const toBase64 = async (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => {
                resolve(reader.result)
            }
            reader.onerror = reject
        })
    }

    useEffect(() => {
        setFiles([])
    }, []);

    useEffect(() => {
        clearPreview && setFiles([])
    }, [clearPreview])

    useEffect(() => {
        if (existingMediaFiles) {
            const ms = existingMediaFiles?.filter(i => i.media_url != null).map(i => { return { media_url: i.media_url } });
            setFiles(ms)
        }

    }, [existingMediaFiles])

    const handleFileUpload = e => {
        const fls = [...e.target.files];
        fls.map(i => toBase64(i).then(res => {
            i["base64"] = res;
            if (multipleUpload === "true") {
                setFiles(files => [...files, i])
                setNewFiles(files => [...files, i])
            } else {
                setFiles([i])
                setNewFiles([i])
            }

        }))
    }

    const removeFromPreview = (name) => {
        const mfs = files?.filter(f => !(f?.name || f?.media_url).includes(name))
        const dfs = files?.filter(f => "media_url" in f && f?.media_url.includes(name));
        setFiles(mfs)
        setNewFiles(mfs);

        if (dfs && setDeletedExistingFile) {
            setDeletedExistingFile(deletedExistingFile.concat(dfs));
        }
    }
    const renderImages = () => {
        return files.map((i, idx) => <PreviewMediaFile key={"preview_" + idx + "_" + i.name || i.media_url || i} src={i} removeFromPreview={removeFromPreview} height={previewHeight} width={previewWidth} />)
    }

    return <>
        <Button onClick={() => inputFile.current.click()} variant="light" className="d-flex flex-row justify-content-center align-items-center font-size-10" style={{ width: width }}>{icon ? icon : <BsImages className="fs-2" />} <span className="ms-2">{cm?.translate(label)}</span></Button>
        {multipleUpload === "true" ? <Form.Control className="d-none" ref={inputFile} type="file" accept="image/*, video/*" multiple onChange={handleFileUpload} onClick={event => event.target.value = null} /> : <Form.Control className="d-none" ref={inputFile} type="file" accept="image/*, video/*" onChange={handleFileUpload} onClick={event => event.target.value = null} />}
        <div className="d-flex flex-wrap align-items-center mt-2">
            {files && renderImages()}
        </div>
    </>
}
export default ImageUploader