import React, { useState } from "react";
import UserReaction from "../UserReaction";
import { MdMenu, MdOutlineKeyboardDoubleArrowRight } from "react-icons/md";
import moment from "moment";
import ModifyAd from "./ModifyAd";
import { BsGlobe, BsLock, BsPersonFillLock, BsQuestionCircle } from "react-icons/bs";
import Swal from "sweetalert2";
import AdPrivacySet from "./AdPrivacySet";
import ExtendTime from "./ExtendTime";
import { useApi } from "../../hooks/useApi"
import { useAuth } from "../../hooks/useAuth";
import useAlert from "../../hooks/useAlert";
import AdOwner from "../admin/AdOwner";
import { useCommon } from "../../hooks/useCommon";
import CreateShorts from "../modals/CreateShorts";
import Orderable from "../modals/ordernow/Orderable";
import PreviewShorts from "../modals/PreviewShorts";


const PostTemplateForMyPage = ({ post, refreshAd }) => {
    const [showModifyModal, setShowModifyModal] = useState(false)
    const [showCreateShorts, setShowCreateShorts] = useState(false)
    const [showOrderable, setShowOrderable] = useState(false)
    const [showExtendTimeModal, setShowExtendTimeModal] = useState(false)
    const [showPrivacyTypeModal, setShowPrivacyTypeModal] = useState(false)
    const api = useApi();
    const auth = useAuth();
    const alert = useAlert();
    const cm = useCommon();

    const renderMediaFiles = () => {
        if (post?.media.length > 0) {
            return post?.media?.map((m, i) => <img src={process.env.REACT_APP_MEDIA_URL + (m?.media_url_resized100x100 || m?.media_url_resized || m?.media_url)} key={"img_" + post?.alias_id + "_" + i} alt="img" className="me-1" style={{ width: 56, height: 56, objectFit: "cover" }} />)
        } else {
            return <img src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" key={"img_" + post?.alias_id} alt="no_img" className="me-1 ratio ratio-1x1" style={{ width: 56, height: 56, objectFit: "contain" }} />
        }
    }

    const handleDelete = () => {
        Swal.fire({
            html: "Are you sure?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, delete it!",
            customClass: {
                confirmButton: "btn btn-sm btn-danger",
                cancelButton: "btn btn-sm btn-secondary ms-2",
            }, buttonsStyling: false
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await api.deleteAd(post?.alias_id);
                    refreshAd(post, "delete")
                } catch (err) {
                    alert.show(err, "error")
                }
            }
        });
    }

    const deleteShorts = async (e) => {
        Swal.fire({
            html: "Are you sure?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, delete it!",
            customClass: {
                confirmButton: "btn btn-sm btn-danger",
                cancelButton: "btn btn-sm btn-secondary ms-2",
            }, buttonsStyling: false
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const res = await api.deleteShorts(post?.alias_id);
                    if (res) {
                        cm?.setRefreshAdInMyAds(post);
                    }
                } catch (err) {
                    alert.show(err, "error")
                }
            }
        });
    }

    const deleteOrderable = () => {
        Swal.fire({
            html: "Are you sure?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, delete it!",
            customClass: {
                confirmButton: "btn btn-sm btn-danger",
                cancelButton: "btn btn-sm btn-secondary ms-2",
            }, buttonsStyling: false
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await api.deleteOrderable(post?.alias_id);
                    cm?.setRefreshAdInMyAds({ alias_id: post?.alias_id });
                } catch (err) {
                    alert.show(err, "error")
                }
            }
        });
    }

    const handleCopyLink = () => {
        navigator.clipboard.writeText(process.env.REACT_APP_API_SERVER + `/ads/${post?.alias_id}/`)
        alert.show("Link copied <br/><a href='" + process.env.REACT_APP_API_SERVER + `/ads/${post?.alias_id}/` + "' style='font-size:8pt'>" + process.env.REACT_APP_API_SERVER + `/ads/${post?.alias_id}/ </a>`, "info")
    }

    const handleExpandTime = () => {
        setShowExtendTimeModal(true)
    }

    const enableOrderableButton = () => {
        const orderableBtn = <>
            <button className="btn btn-sm btn-white border border-opacity-25 rounded-pill p-0 px-2" onClick={e => setShowOrderable(true)}>{cm?.translate("Create")}</button>
            {showOrderable && <Orderable adAliasId={post?.alias_id} setShowOrderable={setShowOrderable} />}
        </>

        if (post && post?.ad_order_option && "unit_price" in post?.ad_order_option) {
            return <>
                <button className="btn btn-sm btn-white border border-opacity-25 rounded-pill py-0 px-2 d-flex align-items-center" onClick={e => setShowOrderable(true)}><span>{post?.ad_order_option?.unit_price + " " + post?.ad_order_option?.currency + " "}</span><span className="ms-2">{post?.ad_order_option?.serving_unit}</span></button>
                <button title={cm?.translate("Delete")} onClick={deleteOrderable} className="btn btn-sm bg-white border border-danger border-opacity-50 text-danger rounded-pill p-0 px-2 ms-2" aria-label="Close">{cm?.translate("Delete")}</button>
                {showOrderable && <Orderable adAliasId={post?.alias_id} existingOrderOption={post?.ad_order_option} setShowOrderable={setShowOrderable} />}
            </>
        } else {
            return orderableBtn
        }
    }

    const enableShortsButton = () => {
        const shortsBtn = <>
            <button
                onClick={(e) => setShowCreateShorts(true)}
                className="btn btn-sm bg-white border border-opacity-25 rounded-pill p-0 px-2"
            >
                {cm?.translate("Create")}
            </button>
            {showCreateShorts && <CreateShorts ad={post} setShowCreateShorts={setShowCreateShorts} />}
        </>

        if (Object.keys(post?.shorts_option).length && post?.shorts_option?.shorts) {
            return <>
                <PreviewShorts shortsOption={post?.shorts_option} />
                <button type="button" title={cm?.translate("Delete")} onClick={deleteShorts} className="btn btn-sm bg-white border border-danger border-opacity-50 text-danger rounded-pill p-0 px-2 ms-2" aria-label="Close">{cm?.translate("Delete")}</button>
            </>
        } else if (Object.keys(post?.shorts_option).length && !post?.shorts_option?.shorts) {
            return <>
                <span>{cm?.translate("In progress..")}</span>
                <button type="button" title={cm?.translate("Delete")} onClick={deleteShorts} className="btn btn-sm bg-white border border-danger border-opacity-50 text-danger rounded-pill p-0 px-2 ms-2" aria-label="Close">{cm?.translate("Delete")}</button>
            </>
        } else {
            return shortsBtn
        }
    }

    return (
        <div
            className="col-sm-12 col-md-6"
        >
            <div className={(moment(post?.updated).fromNow().toString().includes("seconds ago") ? " border-danger " : post?.is_approved ? " border-success " : " border-secondary ") + "thumb-hover border border-2 border-opacity-25 d-flex flex-column font-size-10"}>
                <div className={(moment(post?.updated).fromNow().toString().includes("seconds ago") ? " bg-danger " : post?.is_approved ? " bg-success " : " bg-secondary ") + "row m-0 bg-opacity-10 border-bottom"}>
                    <div className="col p-1 font-size-11"><a href={"/ads/" + post.alias_id} target="_blank" rel="noreferrer" className="text-decoration-none link-dark fw-semibold">{post?.title}</a></div>
                    <div className="col-1 p-1 text-end">
                        <div className="dropdown">
                            <button className="btn btn-sm btn-outline-dark border-0 rounded-0 p-0 lh-1" type="button" data-bs-toggle="dropdown">
                                <MdMenu className="fs-4" />
                            </button>
                            <ul className="dropdown-menu dropdown-menu-end">
                                {post?.is_expired !== true &&
                                    <button className="dropdown-item btn btn-success font-size-10" onClick={() => setShowModifyModal(true)}>{cm?.translate("Modify")}</button>
                                }
                                <button className="dropdown-item btn btn-success font-size-10" onClick={handleDelete}>{cm?.translate("Delete")}</button>
                                {post?.is_expired !== true && <button className="dropdown-item btn btn-success font-size-10" onClick={handleCopyLink}>{cm?.translate("Copy Link")}</button>}
                            </ul>
                        </div>
                    </div>
                </div>
                {auth?.authUser?.is_superuser &&
                    <div className="row m-0 bg-light border-bottom">
                        <div className="col-3 p-2">{cm?.translate("Alias Id")}</div>
                        <div className="col bg-white p-2 text-break">{post?.alias_id}</div>
                    </div>
                }
                {post?.media.length > 0 &&
                    <div className="row m-0 bg-light border-bottom">
                        <div className="col-3 p-2">{cm?.translate("Photos")}</div>
                        <div className="col bg-white p-2">
                            {renderMediaFiles()}
                        </div>
                    </div>
                }
                <div className="row m-0 bg-light border-bottom">
                    <div className="col-3 p-2">{cm?.translate("Category")}</div>
                    <div className="col bg-white p-2">{post?.category?.group?.name} <MdOutlineKeyboardDoubleArrowRight />                    {post?.category?.name}</div>
                </div>
                <div className="row m-0 bg-light border-bottom">
                    <div className="col-3 p-2">{cm?.translate("Member Reaction")}</div>
                    <div className="col bg-white p-2 d-flex align-items-center"><UserReaction post={post} size={"fs-6"} /></div>
                </div>

                <div className="row m-0 bg-light border-bottom">
                    <div className="col-3 p-2">
                        <img src={process.env.PUBLIC_URL + "/static/shorts.png"} height={20} alt="shorts_logo" />
                    </div>
                    <div className="col bg-white p-2 d-flex align-items-center justify-content-between">
                        {enableShortsButton()}
                    </div>
                </div>
                {
                    post?.category?.group?.name !== "Job" &&
                    < div className="row m-0 bg-light border-bottom">
                        <div className="col-3 p-2 d-flex justify-content-between align-items-center">
                            <img src={process.env.PUBLIC_URL + "/static/orderable.png"} height={22} alt="orderable_logo" />
                            <a href="/docs?title=what_is_orderable" target="_blank" className="text-decoration-none link-secondary"><BsQuestionCircle className="fs-6 text-secondary" /></a>
                        </div>
                        <div className="col bg-white p-2 d-flex align-items-center justify-content-between">
                            {enableOrderableButton()}
                        </div>
                    </div>
                }
                <div className="row m-0 bg-light border-bottom">
                    <div className="col-3 p-2">{cm?.translate("Privacy")}</div>
                    <div className="col bg-white p-2">
                        <button className="btn btn-sm btn-white border border-opacity-25 rounded-pill py-0 px-1 d-flex align-items-center" onClick={() => setShowPrivacyTypeModal(!showPrivacyTypeModal)}>{post?.privacy?.name === "Public" ? <BsGlobe className="fs-6 me-1" /> : post?.privacy?.name === "Private" ? <BsLock className="fs-6 me-1" /> : <BsPersonFillLock className="fs-6 me-1" />} {post?.privacy?.name}</button>
                        {showPrivacyTypeModal && <AdPrivacySet post={post} showPrivacyTypeModal={showPrivacyTypeModal} setShowPrivacyTypeModal={setShowPrivacyTypeModal} refreshAd={refreshAd} />}
                    </div>
                </div>


                <div className="row m-0 bg-light border-bottom">
                    <div className="col-3 p-2">{cm?.translate("Ad. Status")}</div>
                    <div className="col bg-white p-2 d-flex align-items-center">{post?.is_approved ? "Approved" : <span className="text-danger">Not Approved</span>}</div>
                </div>

                <div className="row m-0 bg-light border-bottom">
                    <div className="col-3 p-2">{cm?.translate("Expire in")}</div>
                    <div className="col bg-white p-2 text-danger">{post?.is_expired === true ? "Expired" : post?.is_expired + " days"}
                        {/* <button className="ms-2 p-0 px-2 btn btn-sm btn-outline-success rounded-pill font-size-9" onClick={handleExpandTime}>Extend Time</button> */}
                    </div>
                    {/* {showExtendTimeModal && <ExtendTime post={post} showExtendTimeModal={showExtendTimeModal} setShowExtendTimeModal={setShowExtendTimeModal} />} */}
                </div>
                <div className="row m-0 bg-light border-bottom">
                    <div className="col-3 p-2">{cm?.translate("Created on")}</div>
                    <div className="col bg-white p-2">{moment(post?.created).format("YYYY-MM-DD HH:mm")}</div>
                </div>
                <div className="row m-0 bg-light">
                    <div className="col-3 p-2">{cm?.translate("Last Modified")}</div>
                    <div className="col bg-white p-2">{moment(post?.modified).format("YYYY-MM-DD HH:mm")}</div>
                </div>
                {auth?.authUser?.is_superuser &&
                    <div className="row m-0 bg-light border-top">
                        <div className="col-3 p-2">{cm?.translate("Ad. Owner")}</div>
                        <div className="col bg-white p-2 d-flex align-items-center">
                            <AdOwner post={post} />
                        </div>
                    </div>
                }

                {showModifyModal && <ModifyAd post={post} setShowModifyModal={setShowModifyModal} refreshAd={refreshAd} />}
            </div>
        </div >
    );
};
export default PostTemplateForMyPage;
