import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useCommon } from "../hooks/useCommon";

const CategoryGroups = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const cm = useCommon();

    useEffect(() => {
        const catGroup = searchParams.get("cat-group");
        if (!catGroup) {
            cm?.setSelectedCategoryGroup({ id: "", name: "" })
            // cm?.setSelectedCategoryGroup(cm?.categoryGroups.find(c => parseInt(c.id) === 1))
        } else {
            cm?.setSelectedCategoryGroup(cm?.categoryGroups.find(c => c.name === catGroup))
        }
    }, [searchParams])

    const handleCategoryGroup = (e) => {
        if (e.target.value) {
            const catGroup = cm?.categoryGroups.find(c => c.id === parseInt(e.target.value))

            setSearchParams({ "cat-group": catGroup?.name })
            cm.setSelectedCategoryGroup(catGroup)
        } else {
            setSearchParams({})
            cm.setSelectedCategoryGroup({})
        }

    }

    const renderCategoryGroup = () => {
        return cm.categoryGroups?.map(g => <option key={"catgroup_" + g.id} value={g?.id}>{cm?.translate(g?.name)}</option>)
    }

    return <div className="flex-fill w-100">
        <select value={cm?.selectedCategoryGroup?.id} className="form-select rounded-pill font-size-11" name="cat-group" onChange={(e) => handleCategoryGroup(e)}>
            <option value="">{cm?.translate("All")}</option>
            {renderCategoryGroup()}
        </select>
    </div>
}
export default CategoryGroups