import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useCommon } from "../../hooks/useCommon";
import { useApi } from "../../hooks/useApi";
import useAlert from "../../hooks/useAlert";

const SendSms = ({ receiver, handleClose, size = "" }) => {
    const [show, setShow] = useState(false);
    const [messageData, setMessageData] = useState({
        receiver: "",
        message: "",
    });

    const cm = useCommon();
    const api = useApi();
    const alert = useAlert();

    useEffect(() => {
        setMessageData({ ...messageData, receiver: receiver })
        setShow(true)
    }, [])

    const handleCloseClick = () => {
        setShow(false);
        handleClose()
        cm?.showLoader(false)
    };

    const handleChange = (e) => {
        const { name, value } = e.target
        setMessageData({ ...messageData, [name]: value })
    }

    const handleSend = async (e) => {
        try {
            cm.showLoader(true, "Sending..");
            const data = { ...messageData, message: messageData?.message }

            const res = await api.sendAdminSms(data);
            if (res) {
                alert.show("SMS was sent", "success");
                handleClose();
            }
            cm.showLoader(false);
        } catch (err) {
            cm.showLoader(false);
            alert.show(err?.response?.data?.msg || err, "error");
        }
    };


    return <Modal size={size} scrollable="true" show={show} onHide={handleCloseClick} centered>
        <Modal.Header closeButton>
            <Modal.Title>{cm?.translate("Send SMS")}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="">

            <div className="row m-0 mb-1">
                <div className={"col-sm-12 col-md-3 d-flex align-items-center bg-light py-1 position-relative"}>
                    {cm?.translate("To")}
                </div>
                <div className="col d-flex align-items-center py-1">
                    <textarea name="receiver" placeholder={cm?.translate("Receiver")} className="form-control" onChange={handleChange} value={messageData?.receiver} rows={1}></textarea>
                </div>
            </div>
            <div className="row m-0 mb-1">
                <div className={"col-sm-12 col-md-3 d-flex align-items-start bg-light py-1 position-relative"}>
                    {cm?.translate("Message")}
                </div>
                <div className="col d-flex align-items-center py-1">
                    <textarea name="message" placeholder={cm?.translate("Write your message (max 116 charecters allowed)")} maxLength={116} className="form-control" rows={5} onChange={handleChange} value={messageData?.message}></textarea>
                </div>
            </div>

        </Modal.Body>
        <Modal.Footer>
            <button
                disabled={!(messageData?.message && messageData?.receiver && messageData?.subject)}
                className="btn btn-sm btn-success"
                onClick={handleSend}
            >
                {cm?.translate("Send")}
            </button>

            <button
                className="btn btn-sm btn-secondary" onClick={handleCloseClick}
            >
                {cm?.translate("Cancel")}
            </button>
        </Modal.Footer>
    </Modal >
}
export default SendSms;