import { OverlayTrigger, Tooltip } from "react-bootstrap"
import { BiSolidUserCircle } from "react-icons/bi"
import { Link } from "react-router-dom"

const MemberThumb = ({ user, size = 24 }) => {
  return <Link to={"/members/" + user?.alias_id} className="text-decoration-none">
    <OverlayTrigger trigger={["hover", "focus"]} overlay={
      <Tooltip>
        <span className="d-block font-size-10">{user?.user_profile?.company_name ? user?.user_profile?.company_name : user?.user_profile?.name ? user?.user_profile?.name : "Name not disclosed"}</span>
        {user?.user_profile?.is_approved && <span className="d-block font-size-9">Verified</span>}
      </Tooltip>
    }>

      {user?.user_profile?.profile_image ? <img className="bg-light rounded-1 object-fit-cover" src={process.env.REACT_APP_MEDIA_URL + user?.user_profile?.profile_image} alt="owner" style={{ height: size, width: size }} /> : <div><BiSolidUserCircle className="text-secondary" style={{ fontSize: size }} /></div>}
    </OverlayTrigger>
  </Link>
}
export default MemberThumb