import "./App.css";
import { Routes, Route } from "react-router-dom";
import Ads from "./components/Ads";
import AdDetail from "./components/AdDetail";
import UserAds from "./components/UserAds";
import Login from "./components/mypage/Login";
import Register from "./components/mypage/Register";
import Profile from "./components/mypage/Profile";
import MyAds from "./components/mypage/MyAds";
import Disclaimer from "./components/mypage/Disclaimer";
import Settings from "./components/mypage/Settings";
import InquiryPage from "./components/mypage/InquiryPage";
import ProtectedRoutes from "./utils/ProtectedRoutes";
import Layout from "./components/Layout";
import { useApi } from "./hooks/useApi";
import { useEffect } from "react";
import StaticPage from "./components/StaticPage";
import { InquiryProvider } from "./hooks/useInquiry";
import { useCommon } from "./hooks/useCommon";
import Error from "./components/Error";
// import { useNavigate } from 'react-router-dom';
import moment from "moment";
import useAlert from "./hooks/useAlert";
import Orders from "./components/mypage/Orders";
import JobApplications from "./components/mypage/JobApplications";
import AdminShorts from "./components/admin/AdminShorts";
import Admin from "./components/admin/Admin";


function App() {
    const api = useApi();
    const cm = useCommon();
    const alert = useAlert();
    // const navigate = useNavigate();

    const getDictionaryFromApi = async () => {
        try {
            if (Object.keys(cm?.dictionary).length <= 0 || moment().hours() % 2 === 0) {
                const res = await api.getDictionary()
                if (res) {
                    cm?.setDictionary(res);
                }
            }
        } catch (err) {
            alert.show(err?.response?.data?.msg, "error")
        }
    }

    const sendMailSignalToServer = async () => {
        try {
            await api.sendMailSignal()
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        getDictionaryFromApi();
        const interval = setInterval(() => {
            sendMailSignalToServer()
        }, 1000 * 60 * 15)
        return () => clearInterval(interval)
    }, [])



    useEffect(() => {
        cm?.error && console.log("error occured", cm?.error)
        // cm?.error && navigate("/error")
        cm?.error && alert.show(cm?.error, "error");
        cm?.error && cm.showLoader(false);
    }, [cm?.error])

    return (
        <div className="App">
            <Routes>
                <Route>
                    <Route element={<Layout />}>
                        <Route index element={<Ads />}></Route>
                        <Route exact path="/ads" element={<Ads />}></Route>
                        <Route
                            exact
                            path="/members/:aliasId"
                            element={<UserAds />}
                        ></Route>

                        <Route
                            exact
                            path="/ads/:aliasId"
                            element={<AdDetail />}
                        ></Route>
                        <Route path="/login" element={<Login />}></Route>
                        <Route path="/register" element={<Register />}></Route>
                        <Route path="/docs" element={<StaticPage />}></Route>
                        <Route path="/error" element={<Error />}></Route>
                    </Route>
                    <Route element={<ProtectedRoutes />}>
                        <Route
                            path="/mypage/disclaimer"
                            element={<Disclaimer />}
                        ></Route>
                        <Route
                            path="/mypage/profile"
                            element={<Profile />}
                        ></Route>
                        <Route
                            path="/mypage/settings"
                            element={<Settings />}
                        ></Route>
                        <Route
                            path="/mypage/ads"
                            element={<MyAds />}
                        ></Route>
                        <Route
                            path="/mypage/orders"
                            element={<Orders />}
                        ></Route>
                        <Route
                            path="/mypage/job-applications"
                            element={<JobApplications />}
                        ></Route>
                        <Route
                            path="/mypage/inquiries"
                            element={<InquiryProvider><InquiryPage /></InquiryProvider>}
                        ></Route>
                    </Route>
                    <Route element={<ProtectedRoutes />}>
                        <Route
                            path="/admin"
                            element={<Admin />}
                        ></Route>

                    </Route>
                </Route>
            </Routes>
        </div >
    );
}

export default App;
