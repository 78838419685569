import MemberThumb from "./MemberThumb"
import { Link } from "react-router-dom";
import { useApi } from "../hooks/useApi";
import { useAuth } from "../hooks/useAuth";
import { useCommon } from "../hooks/useCommon";
import useAlert from "../hooks/useAlert";
import useRenewAuth from "../hooks/useRenewAuth";
import FollowerSearch from "./modals/FollowerSearch";

const UserProfile = ({ user, setUser }) => {
    const api = useApi();
    const auth = useAuth();
    const cm = useCommon();
    const alert = useAlert();
    const renewAuth = useRenewAuth()

    const handleFollow = async () => {
        try {
            const res = await api.updateUserFollower(user?.alias_id);
            setUser(res)
            cm?.setAdsCache({})
            renewAuth.renew();
        } catch (err) {
            alert.show(err, "error")
        }
    }

    const renderFollowUnfollowButton = () => {
        if (auth.authUser && auth.authUser?.alias_id !== user?.alias_id) {
            if (!user?.is_followed_by_auth_user) {
                return <button onClick={handleFollow} className="btn btn-sm btn-light border-secondary rounded-pill px-3">{cm?.translate("Follow")}</button>
            } else {
                return <button onClick={handleFollow} className="btn btn-sm btn-success rounded-pill px-3">{cm?.translate("Following")}</button>
            }
        }
    }

    const renderBusinessOrPersonalInfo = () => {
        return <div>
            {<h5 className="mb-0">{user?.user_profile?.company_name || user?.user_profile?.name || cm?.translate("No Name")}</h5>}
            {user?.user_profile?.web_link && <Link to={user?.user_profile?.web_link} target="_blank" className="text-decoration-none link-success font-size-10 d-block">{user?.user_profile?.web_link}</Link>}
            <span className="font-size-10 d-block">{user?.user_profile?.location?.name}</span>
        </div>
    }

    return <div className="row m-0 bg-success bg-opacity-10 border border-success border-opacity-25 rounded">
        <div className="col-sm-12 col-md-6 p-2 d-flex">
            <MemberThumb user={user} size={100} />
            <div className="ms-2">
                {renderBusinessOrPersonalInfo()}
                <div className="mt-2">
                    {renderFollowUnfollowButton()}
                </div>
            </div>
        </div>
        <div className="col-sm-12 col-md-6 d-flex flex-wrap justify-content-center align-items-center p-1">
            <div className="row row-cols-3 gx-2 gy-2 w-100">
                <div className="col">
                    <button className="btn btn-sm w-100 bg-success bg-opacity-25 d-flex justify-content-between align-items-center rounded" style={{ pointerEvents: "none" }}><span className="fs-6">{cm?.translate("Ads.")}</span><span className="fs-2 text-dark lh-1">{user?.ads}</span></button>
                </div>
                <div className="col">
                    {Object.keys(user).length && <FollowerSearch user={user} title="Followers" resource="followers" showTotal={true} disabled={true} />}
                </div>
                <div className="col">
                    {Object.keys(user).length && <FollowerSearch user={user} title="Followings" resource="followings" showTotal={true} disabled={true} />}
                </div>
                <div className="col">
                    <button className="btn btn-sm w-100 bg-success bg-opacity-25 d-flex justify-content-between align-items-center rounded" style={{ pointerEvents: "none" }}><span className="fs-6">{cm?.translate("Likes")}</span><span className="fs-2 text-dark lh-1">{user?.likes}</span></button>
                </div>
                <div className="col">
                    <button className="btn btn-sm w-100 bg-success bg-opacity-25 d-flex justify-content-between align-items-center rounded" style={{ pointerEvents: "none" }}><span className="fs-6">{cm?.translate("Views")}</span><span className="fs-2 text-dark lh-1">{user?.views}</span></button>
                </div>
                <div className="col">
                    <button className="btn btn-sm w-100 bg-success bg-opacity-25 d-flex justify-content-between align-items-center rounded" style={{ pointerEvents: "none" }}><span className="fs-6">{cm?.translate("Comments")}</span><span className="fs-2 text-dark lh-1">{user?.comments}</span></button>
                </div>
            </div>
        </div>
    </div>
}
export default UserProfile