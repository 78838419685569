// import sha256 from "crypto-js/sha256";
// import CryptoJS from "crypto-js";
// import moment from "moment";
// import "moment/locale/bn";

import moment from "moment";

// export const encrypt = (
//     content,
//     password = process.env.REACT_APP_ENCRYPT_DECRYPT_SECRET
// ) => CryptoJS.AES.encrypt(content, password).toString();

// export const decrypt = (
//     crypted,
//     password = process.env.REACT_APP_ENCRYPT_DECRYPT_SECRET
// ) => CryptoJS.AES.decrypt(crypted, password).toString(CryptoJS.enc.Utf8);

export const getAge = (date) => {
    const d = moment(date).fromNow().split(" ");
    return d[0] + " " + d[1];
}

export const decodeSearchParams = (searchParams) => {
    return [...searchParams.entries()].reduce((acc, [key, val]) => {
        return {
            ...acc,
            [key]: val
        };
    }, {});
};



export const translateDigit = (nums, language = "bn") => {
    if (language === "en") {
        return nums;
    }
    const nums_str = nums.toString();
    const num_en_bn = {
        ".": ".",
        0: "০",
        1: "১",
        2: "২",
        3: "৩",
        4: "৪",
        5: "৫",
        6: "৬",
        7: "৭",
        8: "৮",
        9: "৯",
    };

    let nums_bn = "";
    for (let i = 0; i < nums_str.length; i++) {
        nums_bn =
            nums_bn +
            (Object.keys(num_en_bn).find((k) => k === nums_str[i])
                ? num_en_bn[nums_str[i]]
                : nums_str[i]);
    }
    return nums_bn;
};

// export const dateTimeToBn = (dateTime, format = "lll") => {
//     moment.locale("bn");
//     const t = moment(dateTime).utcOffset("+0600");
//     return t.format(format);
// };

// export const validateEmail = (mail) => {
//     const validRegex =
//         /(?:[a-z0-9+!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i;

//     return mail.match(validRegex) ? true : false;
// };
export const getDifference = (a, b) => Object.entries(b).filter(([key, val]) => a[key] !== val && key in a).reduce((a, [key, v]) => ({ ...a, [key]: v }), {});


export const getYouTubeEmbededLink = (url) => {
    const embeddedUrl = url.replace("shorts", "embed")
    return embeddedUrl
}