import { Modal } from "react-bootstrap";
import { useState, useEffect, useRef } from "react";
import { useApi } from "../../hooks/useApi"
import useAlert from "../../hooks/useAlert"
import { useCommon } from "../../hooks/useCommon";
import useAudio from "../../hooks/useAudio";
import { BsPlayCircle, BsStopCircle } from "react-icons/bs";

const CreateShorts = ({ ad, setShowCreateShorts }) => {
  const [show, setShow] = useState(false);
  const [audios, setAudios] = useState([]);
  const [images, setImages] = useState([]);

  const [shortsOption, setShortsOption] = useState({
    ad_alias_id: "",
    title: "",
    selected_images: [],
    duration: 30,
    audio: ""
  })
  const [playing, toggle] = useAudio(audios?.length > 0 ? audios?.find(a => a.name === shortsOption?.audio)?.audio || "" : "")

  const cm = useCommon()
  const api = useApi()
  const alert = useAlert()

  const handleClose = () => {
    setShow(false);
    setShowCreateShorts(false);
    cm.showLoader(false)
  };

  const getAudios = async () => {
    try {
      cm.showLoader(true);
      const res = await api?.getAudios();
      if (res) {
        setAudios(res?.results);
      }
      cm.showLoader(false);
    } catch (err) {
      alert.show(err?.response?.data?.msg || err, "error")
      cm.showLoader(false);
    }
  };

  const callCreateShorts = async () => {
    try {
      cm.showLoader(true);
      const res = await api?.createShorts(shortsOption);
      if (res) {
        alert.show(cm?.translate("Shorts was created and will available in next 30 minutes."), "success")
        cm?.setRefreshAdInMyAds({ alias_id: ad?.alias_id });
        handleClose();
      }
      cm.showLoader(false);
    } catch (err) {
      alert.show(err?.response?.data?.msg || err, "error")
      cm.showLoader(false);
    }
  };

  useEffect(() => {
    setShow(true);
    setShortsOption({ ...shortsOption, ad_alias_id: ad?.alias_id, title: ad?.title + " | " + (ad?.owner?.user_profile?.company_name || ad?.owner?.user_profile?.name) })
  }, [])

  useEffect(() => {
    show && ad && getAudios();
    show && ad && setImages(ad?.media?.map(i => i.media_url_resized100x100))
  }, [show, ad])

  // useEffect(() => {
  //   console.log(shortsOption)
  // }, [shortsOption])

  const handleShortsOptionInput = (e) => {
    if (playing) {
      toggle()
    }
    let { name, value } = e.target
    let data = { ...shortsOption, [name]: value }

    setShortsOption({ ...data })
  }

  const handleSelectImageForShorts = (e) => {
    const { name, value } = e.target;
    const selectedImage = shortsOption?.selected_images?.find(i => i === name)
    let si;
    if (selectedImage === undefined) {
      si = shortsOption.selected_images
      si.push(name);
    } else {
      si = shortsOption.selected_images.filter(i => i !== name)
    }
    setShortsOption({ ...shortsOption, selected_images: si })

  }
  const renderImages = () => {
    return images?.map((im, ix) => {
      return < div key={"shorts_img_" + ix} className="d-flex flex-column align-items-center mx-1 mb-1" >
        <img src={process.env.REACT_APP_MEDIA_URL + im} alt="shorts_img" className="object-fit-cover" height={128} width={72} />
        <input name={im} onChange={(e) => handleSelectImageForShorts(e)} className="form-check-input border-secondary fs-5" type="checkbox" ></input>
      </div >
    })
  }

  const renderAudiosList = () => {
    return audios?.map(a => <option key={"audios_" + a.id} value={a.name}>{cm?.translate(a?.name)}</option>
    )
  }

  return (
    <>
      {show && <Modal size="" backdrop="static" scrollable="true" show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title className="d-flex align-items-center"><img src={process.env.PUBLIC_URL + "/static/shorts.png"} height={30} alt="shorts_logo" className="" /></Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-4">
          <div className="">
            <input type="text" name="title" className="form-control" placeholder={cm?.translate("Title")} onChange={handleShortsOptionInput} value={shortsOption?.title}></input>
            <select name="duration" className="form-select mt-3" onChange={handleShortsOptionInput} value={shortsOption?.currency}>
              <option value={30}>{cm?.translate("Duration 30 Seconds (Free)")}</option>
              {/* <option value={60}>{cm?.translate("Duration 60 Seconds (Paid)")}</option> */}
            </select>
          </div>
          <div className="mt-3 d-flex align-items-center">
            {images.length > 0 && renderImages()}
          </div>
          <span className="mt-2 font-size-10 d-block">{images.length > 0 ? cm?.translate("Check mark images to create Shorts") : cm?.translate("Images are not uploaded")}</span>

          <div className="mt-3 d-flex align-items-center">
            <select name="audio" className="form-select" onChange={handleShortsOptionInput} value={shortsOption?.audio}>
              <option value="">{cm?.translate("Select Background Music")}</option>
              {renderAudiosList()}
            </select>
            {shortsOption?.audio && <button className="btn btn-sm ms-1" onClick={toggle}>{playing ? <BsStopCircle className="fs-5" /> : <BsPlayCircle className="fs-5" />}</button>}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button disabled={shortsOption?.selected_images.length <= 0} className="btn btn-sm btn-success" onClick={callCreateShorts}>{cm?.translate("Create")}</button>
          <button className="btn btn-sm btn-secondary" onClick={handleClose}>{cm?.translate("Cancel")}</button>
        </Modal.Footer>
      </Modal >
      }

    </>
  );
}
export default CreateShorts