import React, { useState } from "react";
import PostTemplate from "./PostTemplate";
import SelectionMenu from "./SelectionMenu";
import { useCommon } from "../hooks/useCommon";
import { Link, useSearchParams } from "react-router-dom";
import { useApi } from "../hooks/useApi";
import { useAuth } from "../hooks/useAuth";
import useAlert from "../hooks/useAlert";
import PostTemplate2 from "./PostTemplate2";
import Pagination from "./Pagination";

const Ads = () => {
    const [searchParams] = useSearchParams();
    const [currentPage, setCurrentPage] = useState(1)
    // const [pageRange, setPageRange] = useState("")

    const cm = useCommon();
    const api = useApi();
    const auth = useAuth();
    const alert = useAlert();

    const getSearchParam = () => {
        const keyword = searchParams.get("search")
        return keyword
    }

    const renderAds = (ff = false) => {
        if (ff) {
            return cm?.ads?.results?.sort((a, b) => b?.owner?.is_followed_by_auth_user - a?.owner?.is_followed_by_auth_user).map((p, i) =>
                (auth?.authUser?.user_settings?.ads_listing_style === "default" || !auth?.authUser) ? <PostTemplate key={"post_" + i} post={p} /> : <PostTemplate2 key={"post_" + i} post={p} />);
        }
        return cm?.ads?.results?.map((p, i) =>
            (auth?.authUser?.user_settings?.ads_listing_style === "default" || !auth?.authUser) ? <PostTemplate key={"post_" + i} post={p} /> : <PostTemplate2 key={"post_" + i} post={p} />);
    };

    const getAdsFromApi = async (params) => {
        try {
            cm.showLoader(true)
            const res = await api.getAds(params || {})
            if (res) {
                cm.setAds(res);
                cm.showLoader(false)
            }
        } catch (err) {
            alert.show(err?.response?.data?.msg || err, "error")
            cm.showLoader(false)
        }
    };

    // const handlePrev = () => {
    //     if (cm?.ads?.previous) {
    //         const params = decodeSearchParams(new URL(cm?.ads?.previous).searchParams);
    //         getAdsFromApi(params);
    //     }
    // }

    // const handleNext = () => {
    //     if (cm?.ads?.next) {
    //         const params = decodeSearchParams(new URL(cm?.ads?.next).searchParams);
    //         getAdsFromApi(params);
    //     }
    // }

    const onChangePage = (e) => {
        getAdsFromApi({ page: e.target.value });
        setCurrentPage(e.target.value)
    }

    // useEffect(() => {
    //     let start, end, count = 0;
    //     if (cm?.ads?.next !== null && cm?.ads?.next !== undefined) {
    //         const params = decodeSearchParams(new URL(cm?.ads?.next).searchParams);
    //         const nextPage = parseInt(params["page"]);
    //         const page = nextPage - 2
    //         end = (page + 1) * cm?.ads?.results?.length
    //         start = end > 0 ? end - cm?.ads?.results?.length + 1 : 0
    //     } else if (cm?.ads?.previous) {
    //         const params = decodeSearchParams(new URL(cm?.ads?.previous).searchParams);
    //         const prevPage = parseInt(params["page"] || 1);
    //         const page = prevPage
    //         const dataLen = parseInt(cm?.ads?.results?.length)
    //         end = dataLen + (page * 100)
    //         start = end > 0 ? end - dataLen + 1 : 0
    //     } else {
    //         end = cm?.ads?.results?.length || 0
    //         start = end > 0 ? 1 : 0
    //     }

    //     start = cm?.translate(start, cm?.language, true)
    //     end = cm?.translate(end, cm?.language, true)
    //     count = cm?.translate(cm?.ads?.count || 0, cm?.language, true)

    //     setPageRange(start + " - " + end + " / " + count)

    // }, [cm?.ads, cm?.ads?.next, cm?.ads?.previous])


    return (
        <div className="px-2 px-md-0">
            <SelectionMenu />
            <div className={"col-sm-12 col-md-10 mx-auto my-4"}>
                <div className="d-flex align-items-center justify-content-end">
                    <div>
                        {getSearchParam() && <Link to="/" className="link-danger font-size-10 me-4 text-decoration-none"><button type="button" className="btn-close font-size-6" aria-label="Close"></button> {cm?.translate("Remove searching for")} '{getSearchParam()}'</Link>}
                        {/* <span className="d-block text-success font-size-10 text-end px-1">{pageRange}</span> */}
                    </div>
                </div>
                <div className={((auth?.authUser?.user_settings?.ads_listing_style === "default" || !auth?.authUser) ? "row g-0" : "row g-2") + " m-0 mt-2"}>{auth?.authUser ? renderAds(false) : renderAds()}</div>
                {/* <div className={(cm?.ads?.previous ? "justify-content-between" : "justify-content-end") + " d-flex my-4"}>
                    {cm?.ads?.previous && <button className="btn btn-light d-flex align-items-center rounded-0" onClick={handlePrev}><BsChevronCompactLeft /> {cm?.translate("Previous")}</button>}
                    {cm?.ads?.next && <button className="btn btn-light d-flex align-items-center rounded-0" onClick={handleNext}>{cm?.translate("Next")} <BsChevronCompactRight /></button>}
                </div> */}
                <div className="mt-4 mb-5">
                    <Pagination totalItems={cm?.ads?.count} onChangePage={onChangePage} currentPage={currentPage} />
                </div>
            </div>
        </div>
    );
};
export default Ads;
