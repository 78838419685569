import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useCommon } from "../../../hooks/useCommon";
import { Link } from "react-router-dom";
import { useApi } from "../../../hooks/useApi";
import useAlert from "../../../hooks/useAlert";

const Orderable = ({ adAliasId, existingOrderOption, setShowOrderable, handleOrderable = null }) => {
  const [show, setShow] = useState(false);
  const cm = useCommon()
  const api = useApi()
  const alert = useAlert()

  const [orderOption, setOrderOption] = useState({
    currency: "Taka",
    unit_price: "",
    tax: "",
    serving_unit: "",
    delivery_charge: "",
    order_related_info: "",
    notification_email: "",
    notification_phone: "",
    notification_api: "",

  })

  useEffect(() => {
    setShow(true)
    existingOrderOption && Object.keys(existingOrderOption).length && setOrderOption({ ...existingOrderOption })
  }, [])


  const handleClose = () => {
    setShow(false);
    setShowOrderable(false);
    cm?.showLoader(false)
  };

  const handleOrderOptionInput = (e) => {
    let { name, value } = e.target
    let data = { ...orderOption, [name]: value }
    setOrderOption({ ...data })
  }

  const saveOrderable = async () => {
    try {
      cm.showLoader(true);
      const ord = { ...orderOption, ad_alias_id: adAliasId }
      let res;
      if (existingOrderOption && Object.keys(existingOrderOption).length) {
        res = await api.updateOrderable(orderOption?.id, ord)
      } else {
        res = await api.createOrderable(ord)
      }

      if (Object.keys(res).length) {
        alert.show("Orderable was set", "success");
        cm?.setRefreshAdInMyAds({ alias_id: adAliasId })
        handleClose();
      }
      cm.showLoader(false);
    } catch (err) {
      alert.show(err?.response?.data?.msg || err, "error")
      cm.showLoader(false);
    }
  };

  const handleSave = () => {
    if (handleOrderable) {
      handleOrderable(orderOption);
      handleClose();
    } else {
      saveOrderable()
    }
  }

  return (
    <Modal backdrop="static" scrollable="true" show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title className="d-flex align-items-center"><img src={process.env.PUBLIC_URL + "/static/orderable.png"} width={120} alt="shorts_logo" /></Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-4">
        <div className="d-flex align-items-center">
          <input type="number" min={0} className="form-control flex-fill" name="unit_price" required placeholder={cm?.translate("Unit price") + " *"} onChange={e => handleOrderOptionInput(e)} value={orderOption?.unit_price || ""}></input>
          <select name="currency" className="form-select ms-2" onChange={handleOrderOptionInput} value={orderOption?.currency}>
            <option value={"Taka"}>{cm?.translate("Taka")}</option>
            <option value={"Dollar"}>{cm?.translate("Dollar")}</option>
            <option value={"Pound"}>{cm?.translate("Pound")}</option>
            <option value={"Yen"}>{cm?.translate("Yen")}</option>
          </select>
          <input type="text" name="serving_unit" className="form-control ms-2" value={orderOption?.serving_unit || ""} placeholder={cm?.translate("Unit")} onChange={e => handleOrderOptionInput(e)}></input>
        </div>
        <div className="mt-2 d-flex align-items-center">
          <input type="number" min={0} className="form-control" name="tax" required placeholder={cm?.translate("% of Tax (if any)")} onChange={e => handleOrderOptionInput(e)} value={orderOption?.tax || ""}></input>
        </div>
        <div className="mt-2 d-flex align-items-center">
          <input type="number" min={0} className="form-control" name="delivery_charge" required placeholder={cm?.translate(`Delivery charge (if any)`)} onChange={e => handleOrderOptionInput(e)} value={orderOption?.delivery_charge || ""}></input>
        </div>
        <div className="mt-2 bg-white rounded">
          <textarea className="form-control" name="order_related_info" placeholder={cm?.translate("Order related information (if any)")} style={{ height: 100 }} onChange={e => handleOrderOptionInput(e)} value={orderOption?.order_related_info.replaceAll("<br />", "\n") || ""} />
        </div>
        <span className="d-block fw-semibold mt-4 mb-1">{cm?.translate("Notification")}</span>
        <div className="d-flex align-items-center">
          <input type="text" className="form-control" name="notification_email" required placeholder={cm?.translate("Email address")} onChange={e => handleOrderOptionInput(e)} value={orderOption?.notification_email || ""}></input>
        </div>
        <div className="mt-2 d-flex align-items-center">
          <input type="text" className="form-control" name="notification_phone" required placeholder={cm?.translate("Phone number")} onChange={e => handleOrderOptionInput(e)} value={orderOption?.notification_phone || ""}></input>
        </div>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-between">
        <a href="/docs?title=what_is_orderable" target="_blank" className="font-size-10 link-dark">{cm?.translate("What is Orderable?")}</a>
        <div>
          <button disabled={!orderOption?.unit_price} className="btn btn-sm btn-success" onClick={handleSave} >{cm?.translate("Save")}</button>
          <button className="btn btn-sm btn-secondary ms-2" onClick={handleClose}>{cm?.translate("Cancel")}</button>
        </div>
      </Modal.Footer>
    </Modal >
  );
}
export default Orderable