import React, { useEffect, useRef, useState } from "react";
import { useCommon } from "../../hooks/useCommon";
import { useApi } from "../../hooks/useApi";
import moment from "moment";
import { Accordion } from "react-bootstrap";
import PostTemplate from "../PostTemplate";
import MemberThumb from "../MemberThumb";
import InquiryReply from "./InquiryReply";
import useAlert from "../../hooks/useAlert";
import { useAuth } from "../../hooks/useAuth";

const InquiryPage = () => {
  const [inquiries, setInquiries] = useState([])
  const [inquiryExpand, setInquiryExpand] = useState(true)
  const cm = useCommon();
  const api = useApi();
  const alert = useAlert();
  const auth = useAuth();

  const convHolder = useRef([]);



  const getInquiries = async (params) => {
    try {
      cm.showLoader(true)
      const res = await api.getInquiries(params || {})
      if (res) {
        setInquiries(res)
        cm.showLoader(false)
      }
    } catch (err) {
      alert.show(err?.response?.data?.msg || err, "error")
      cm.showLoader(false)
    }
  };

  const getInquiryFromApi = async (id) => {
    try {
      cm.showLoader(true);
      const res = await api.getInquiry(id)
      if (res) {
        const filter = inquiries?.results?.filter(r => r.id !== id);
        const results = [res, ...filter];
        setInquiries({ ...inquiries, results: results })
        cm.showLoader(false)
      }
    } catch (err) {
      alert.show(err?.response?.data?.msg || err, "error")
      cm.showLoader(false)
    }
  };

  const onReply = (id) => {
    getInquiryFromApi(id)
  }

  useEffect(() => {
    getInquiries()
  }, [])


  const scrollToBottom = () => {
    convHolder?.current[inquiryExpand].scrollTo({ top: convHolder?.current[inquiryExpand].scrollHeight })
  }

  useEffect(() => {
    inquiryExpand in convHolder?.current && scrollToBottom();
  }, [inquiryExpand])

  const renderInquiries = () => {
    return inquiries?.results?.map((i, ix) => {
      return <Accordion.Item eventKey={"inq_" + i.id} key={"inq_" + ix}>
        <Accordion.Header>
          <div className="d-flex justify-content-between align-items-center py-1">
            <MemberThumb user={i?.inquirer} size={32} />
            <a href={"/members/" + i?.inquirer?.alias_id} target="_blank" className="ms-1 text-decoration-none link-dark" rel="noreferrer">{i?.inquirer?.user_profile?.name}</a> <span className="ms-2 font-size-8 text-secondary">{moment(i?.created).format("YYYY-MM-DD HH:mm")}</span>
          </div>
        </Accordion.Header>
        <Accordion.Body ref={el => convHolder.current["inq_" + i.id] = el} className="p-0 overflow-scroll" style={{ maxHeight: 500 }}>
          <div className="d-flex flex-column p-3">
            <PostTemplate post={i?.ad} disabled={true} fullWidth={true} />
            <p dangerouslySetInnerHTML={{ __html: i?.message }} className="mt-3 text-bg-warning bg-opacity-25 p-3 rounded font-size-11"></p>
            {replies(i)}
          </div>
          <div className="bg-light p-3 rounded-bottom sticky-bottom border-top">
            <InquiryReply inquiry={i} onReply={onReply} />
          </div>
        </Accordion.Body>
      </Accordion.Item >
    }
    );
  };

  const replies = (inquiry) => {
    return inquiry?.replies?.map((r, idx) =>
      r?.respondent?.alias_id === auth?.authUser?.alias_id ?
        <div key={"rep_" + r?.id} className={"d-flex justify-content-end"}>
          <p dangerouslySetInnerHTML={{ __html: r?.message }} className={"text-bg-light p-3 rounded font-size-11 ms-2"}></p>
        </div>
        :
        <div key={"rep_" + r?.id} className={"d-flex"}>
          <MemberThumb user={r?.respondent} size={32} />
          <p dangerouslySetInnerHTML={{ __html: r?.message }} className={"text-bg-light p-3 rounded font-size-11 ms-2"}></p>
        </div>
    )
  }

  return (
    <div className="col-sm-12 col-md-10 mx-auto my-4">
      <Accordion className="my-5" onSelect={(e) => setInquiryExpand(e)}>
        {renderInquiries()}
      </Accordion>
    </div>
  );
};

export default InquiryPage;
