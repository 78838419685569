import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useApi } from "../../hooks/useApi"
import useAlert from "../../hooks/useAlert"
import { useCommon } from "../../hooks/useCommon";
import { Link } from "react-router-dom";
import MemberThumb from "../MemberThumb";

const AdOwner = ({ post }) => {
    const [show, setShow] = useState(false);
    const [owner, setOwner] = useState({});
    const api = useApi();
    const cm = useCommon();
    const alert = useAlert();


    const handleClose = () => { setShow(false); }

    const getOwnerDetail = async () => {
        try {
            const res = await api.getUserDetail(post?.owner?.alias_id);
            if (res) {
                setOwner(res)

            }
        } catch (err) {
            alert.show(err?.message, "error")
        }
    };




    useEffect(() => {
        show && post && getOwnerDetail()
    }, [show])

    return <>
        <Link onClick={() => setShow(true)} className="link-primary text-decoration-none">{post?.owner?.user_profile?.name || post?.owner?.user_profile?.company_name || post?.owner?.alias_id}</Link>

        <Modal size="lg" scrollable="true" show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>Ad. Owner</Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-4 font-size-10">
                <MemberThumb user={post?.owner} size={64} />
                <div className="row m-0 d-flex align-items-center mb-1 mt-2 bg-light p-2 px-0 ">
                    <div className="col-2">
                        <span>ID</span>
                    </div>
                    <div className="col">
                        {owner?.alias_id}
                    </div>
                </div>
                <div className="row m-0 d-flex align-items-center mb-1 bg-light p-2 px-0">
                    <div className="col-2">
                        <span>Name</span>
                    </div>
                    <div className="col">
                        {owner?.user_profile?.name}
                    </div>
                </div>

                <div className="row m-0 d-flex align-items-center mb-1 bg-light p-2 px-0">
                    <div className="col-2">
                        <span>Location</span>
                    </div>
                    <div className="col">
                        {owner?.user_profile?.location?.name}
                    </div>
                </div>

                <div className="row m-0 d-flex align-items-center mb-1 bg-light p-2 px-0">
                    <div className="col-2">
                        <span>Address</span>
                    </div>
                    <div className="col">
                        {owner?.user_profile?.address}
                    </div>
                </div>
                <div className="row m-0 d-flex align-items-center mb-1 bg-light p-2 px-0">
                    <div className="col-2">
                        <span>Email or Phone</span>
                    </div>
                    <div className="col">
                        {owner?.email_or_phone}
                    </div>
                </div>

                <div className="row m-0 d-flex align-items-center mb-1 bg-light p-2 px-0">
                    <div className="col-2">
                        <span>Company</span>
                    </div>
                    <div className="col">
                        {owner?.user_profile?.company_name}
                    </div>
                </div>

                <div className="row m-0 d-flex align-items-center mb-1 bg-light p-2 px-0">
                    <div className="col-2">
                        <span>Website</span>
                    </div>
                    <div className="col">
                        {owner?.user_profile?.web_link}
                    </div>
                </div>

                <div className="row m-0 d-flex align-items-center mb-1 bg-light p-2 px-0">
                    <div className="col-2">
                        <span>Total Ad.</span>
                    </div>
                    <div className="col">
                        {owner?.ads}
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button className="btn btn-sm btn-secondary" onClick={handleClose}>{cm?.translate("Cancel")}</Button>
            </Modal.Footer>
        </Modal>
    </>
}
export default AdOwner;