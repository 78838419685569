import Autolinker from "autolinker";
import { useEffect, useRef, useState } from "react"
import sanitizeHtml from 'sanitize-html';


const MonozunaEditor = ({ content, updateHtmlContent, name = "", placeholder = "", style = {} }) => {
    const [isFocused, setIsFocused] = useState(false)
    const [initContent, setInitContent] = useState("")
    const maskContent = useRef("");
    const editableView = useRef();

    const replaceRawToHtml = (content) => {
        const html = content.replaceAll("\n", "<br/>")
        return html
    }


    useEffect(() => {
        maskContent.current = content || ""
        setInitContent(content || "")
    }, [content])


    const handleInput = (e) => {
        maskContent.current = e.target.innerHTML
        if (maskContent.current === "" || maskContent.current === "<br />") {
            maskContent.current = ""
        }
    }

    const handleSanitize = (e) => {
        setIsFocused(false)
        if (maskContent.current === "" || maskContent.current === "<br />") {
            maskContent.current = ""
            updateHtmlContent({ [name]: replaceRawToHtml(maskContent.current) });
            return;
        }
        content = sanitizeHtml(e.target.innerHTML, {
            allowedTags: ["b", "i", "em", "strong", "a", "p", "h3", "h4",
                "h5", "h6", "div", "table", "tbody", "td", "tfoot", "th", "thead", "tr", "small", "span", "strong", "sub", "sup", "u",
                "col", "colgroup", "ol", "p", "pre",
                "ul", "br", "img", "svg"],
            allowedAttributes: { a: ['href', 'target', 'title', 'style'], img: ['src', 'alt', 'title', 'width', 'height', 'style'] },
            selfClosing: ['img', 'br', 'hr', 'area', 'base', 'basefont', 'input', 'link', 'meta'],
            allowedSchemes: ['http', 'https', 'mailto', 'tel'],
            allowedIframeHostnames: ['www.youtube.com'],
            enforceHtmlBoundary: true,
            allowIframeRelativeUrls: true
        });
        content = content.replaceAll(/&lt;/g, "<")
        content = content.replaceAll(/&gt;/g, ">")
        content = content.replaceAll(/&amp;/g, "&")
        content = replaceRawToHtml(content)
        content = Autolinker.link(content)

        maskContent.current = content
        updateHtmlContent({ [name]: content })
    }


    const handleMouseDown = (e) => {
        setIsFocused(true)
        editableView.current.focus()
    }

    return <div className="position-relative" style={style}>
        <div ref={editableView} contentEditable="true" onFocus={handleMouseDown} onMouseDown={handleMouseDown} onBlur={handleSanitize} className="form-control overflow-scroll bg-white bg-opacity-10 position-absolute start-0 top-0 z-1" style={style} onInput={handleInput} dangerouslySetInnerHTML={{ __html: maskContent?.current || initContent }}></div>
        {(isFocused === false && maskContent?.current === "") && <span className="position-absolute start-0 top-0 mt-2 text-secondary z-0" style={{ marginLeft: 14 }}>{placeholder}</span>}
    </div>
}


export default MonozunaEditor