import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useCommon } from "../hooks/useCommon";
import { decodeSearchParams } from "../utils/utils";
import useAlert from "../hooks/useAlert";

const Categories = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [categories, setCategories] = useState([])
    const cm = useCommon();
    const alert = useAlert();

    useEffect(() => {
        const category = searchParams.get("category");

        if (!category) {
            cm?.setSelectedCategory({})
        } else {
            cm?.setSelectedCategory(cm?.categories.find(c => c?.name === category))
        }
    }, [searchParams])


    const getCategoriesFromBackup = async (filter) => {
        try {
            const filterCats = cm?.categories.filter(c => c?.group?.id === filter?.id);
            const sortedFilterCats = filterCats.sort((a, b) => {
                if (a.name > b.name) return 1;
                if (a.name < b.name) return -1;
                return 0;
            })
            setCategories(sortedFilterCats);
        } catch (err) {
            alert.show(err?.message, "error")
        }
    };

    useEffect(() => {
        getCategoriesFromBackup(cm.selectedCategoryGroup);
    }, [cm.selectedCategoryGroup])


    const handleCategory = (e) => {
        const cat = cm?.categories.find(c => c.id === parseInt(e.target.value))
        cm?.setSelectedCategory(cat || {})

        let params = decodeSearchParams(searchParams)
        delete params.location;
        if (parseInt(e.target.value) >= 0) {
            setSearchParams({ ...params, category: cm?.categories.find(c => c.id === parseInt(e.target.value))?.name })
        } else {
            delete params.category;
            setSearchParams(params)
        }
    }

    const renderCategories = () => {
        return categories && categories?.map(c => <option key={"cat_" + c.id} value={c?.id} >{cm?.translate(c?.name)} ({cm?.translate(c?.total_ads, cm?.language, true)})</option>)
    }

    return <div className="ms-0 mt-2 ms-md-2 mt-md-0 flex-fill w-100">
        <select name="category" value={String(cm.selectedCategory?.id)} disabled={categories.length === 0} className="form-select rounded-pill font-size-11" onChange={(e) => handleCategory(e)}>
            <option value={"-" + cm?.selectedCategoryGroup?.id}>{categories.length > 0 ? cm?.translate("All Categories") : cm?.translate("No category available")}</option>
            {renderCategories()}
        </select>
    </div>
}
export default Categories