import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useCommon } from "../hooks/useCommon";
import { decodeSearchParams } from "../utils/utils";

const Locations = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const cm = useCommon();

    useEffect(() => {
        const location = searchParams.get("location");

        if (!location) {
            cm?.setSelectedLocation({})
        } else {
            cm?.setSelectedLocation(cm?.locations.find(l => l?.name === location))
        }
    }, [searchParams])

    const handleLocation = (e) => {
        const sl = cm?.locations.find(l => parseInt(l.id) === parseInt(e.target.value))
        cm?.setSelectedLocation(sl || {});

        let params = decodeSearchParams(searchParams);
        if (parseInt(e.target.value) >= 0) {
            const changedSP = { ...params, location: cm?.locations.find(l => parseInt(l.id) === parseInt(e.target.value))?.name }
            setSearchParams(changedSP)
        } else {
            delete params.location;
            setSearchParams(params)
        }
    }

    const renderLocations = () => {
        return cm?.locations && cm?.locations?.map(l => <option key={"location_" + l.id} value={l?.id} >{cm?.translate(l?.name)} ({cm?.translate(l?.total_ads, cm?.language, true)})</option>)
    }

    return <div className="ms-0 mt-2 ms-md-2 mt-md-0 flex-fill w-100">
        <select name="location" value={String(cm.selectedLocation?.id)} className="form-select rounded-pill font-size-11" onChange={(e) => handleLocation(e)}>
            <option value={""}>{cm?.translate("All Locations")}</option>
            {renderLocations()}
        </select>
    </div>
}
export default Locations