import React, { useState } from "react";
import { useEffect } from "react";
import { useApi } from "../../hooks/useApi"
import { useAuth } from "../../hooks/useAuth"
import { useCommon } from "../../hooks/useCommon";
import { BsChevronCompactLeft, BsChevronCompactRight, BsSearch } from "react-icons/bs";
import useAlert from "../../hooks/useAlert";
import { Accordion } from "react-bootstrap";
import moment from "moment";
import PostTemplate from "../PostTemplate";

const Orders = () => {
    const [orders, setOrders] = useState([])
    const [backupOrders, setBackupOrders] = useState([])
    const [pageRange, setPageRange] = useState("")
    const [search, setSearch] = useState("")
    const [orderDirection, setOrderDirection] = useState({})

    const api = useApi();
    const auth = useAuth();
    const cm = useCommon()
    const alert = useAlert()


    const getMyOrders = async (params) => {
        try {
            cm.showLoader(true);
            const res = await api?.getOrders(params);
            if (res) {
                setOrders(res?.results);
                setBackupOrders(res);
            }
            cm.showLoader(false);
        } catch (err) {
            alert.show(err?.response?.data?.msg || err, "error")
            cm.showLoader(false);
        }
    };


    useEffect(() => {
        setOrderDirection("sent")
        getMyOrders({ direction: "sent" });
    }, [])

    const handleSearch = async (e) => {
        setSearch(e.target.value)
        if (e.key === 'Enter') {
            getMyOrders({ search: e.target.value, direction: orderDirection });
        }
    }

    const handleSearchClicked = async (e) => {
        getMyOrders({ direction: orderDirection, search: search });
    }

    const handleChangeOrderDirection = (direction) => {
        setOrderDirection(direction)
        getMyOrders({ direction: direction });
    }

    const renderOrders = () => {
        return orders?.map((o, i) => {
            const postAtOrder = JSON.parse(o.post_info_at_order);
            return <Accordion.Item eventKey={i} key={"ord_" + i} >
                <Accordion.Header>{o.order_no} <span className="ms-3 font-size-9 text-secondary">{moment(o.order_date).format("YYYY-MM-DD HH:mm")}</span></Accordion.Header>
                <Accordion.Body className="py-4">
                    <div className="d-flex flex-column justify-content-between align-items-stretch">
                        <PostTemplate post={postAtOrder} disabled={true} fullWidth={true} />
                        <div className="d-flex flex-column flex-md-row justify-content-between align-items-stretch m-0 mt-2">
                            <div className="col bg-warning bg-opacity-25 rounded p-3 shadow-sm me-0 me-md-1 mt-2 mt-md-0">
                                <div className="d-flex justify-content-between">
                                    <span className="font-size-11">{cm?.translate("Quantity")}</span>
                                    <span>{o?.quantity}</span>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <span className="font-size-11">{cm?.translate("Total Price")}</span>
                                    <div className="">
                                        <span className="">{o?.total_price}<span className="ms-1 font-size-9">{o?.currency}</span></span>
                                    </div>
                                </div>
                                {o?.tax > 0 && <div className="d-flex justify-content-between">
                                    <span className="font-size-11">{cm?.translate("Tax")}</span>
                                    <div className="">
                                        <span className="">{o?.tax}<span className="ms-1 font-size-9">{o?.currency}</span></span>
                                    </div>
                                </div>}
                                {o?.delivery_charge > 0 && <div className="d-flex justify-content-between">
                                    <span className="font-size-11">{cm?.translate("Delivery Charge")}</span>
                                    <div className="">
                                        <span className="">{o?.delivery_charge}<span className="ms-1 font-size-9">{o?.currency}</span></span>
                                    </div>
                                </div>}
                                <div className="d-flex justify-content-between text-danger fw-semibold">
                                    <span className="font-size-11">{cm?.translate("Total Pay")}</span>
                                    <div className="">
                                        <span className="">{o?.total_pay}<span className="ms-1 font-size-9">{o?.currency}</span></span>
                                    </div>
                                </div>
                            </div>
                            <div className="col ms-0 mt-2 mt-md-0 ms-md-1 bg-light rounded shadow-sm p-3 d-flex flex-column justify-content-center align-items-center">
                                <span className="font-size-11 fw-semibold">{cm?.translate("Delivery Instruction")}</span>
                                <p className="mb-0 font-size-11" dangerouslySetInnerHTML={{ __html: o?.delivery_info }}></p>
                            </div>
                        </div>
                    </div>

                </Accordion.Body>
            </Accordion.Item >
        }
        );
    };

    const handlePrev = () => {
        if (backupOrders?.previous) {
            const page = backupOrders?.previous.split("=")[1];
            getMyOrders({ page: page });
        }
    }

    const handleNext = () => {
        if (backupOrders?.next) {
            const page = backupOrders?.next.split("=")[1];
            getMyOrders({ page: page });
        }
    }

    const decodeSearchParams = (searchParams) => {
        return [...searchParams.entries()].reduce((acc, [key, val]) => {
            return {
                ...acc,
                [key]: val
            };
        }, {});
    };

    useEffect(() => {
        let start, end, count = "";
        if (backupOrders?.next !== null && backupOrders?.next !== undefined) {
            const params = decodeSearchParams(new URL(backupOrders?.next).searchParams);
            const nextPage = parseInt(params["page"]);
            const page = nextPage - 2
            end = (page + 1) * orders?.length
            start = end > 0 ? end - orders?.length + 1 : 0
        } else if (backupOrders?.previous) {
            const params = decodeSearchParams(new URL(backupOrders?.previous).searchParams);
            const prevPage = parseInt(params["page"] || 1);
            const page = prevPage
            const dataLen = parseInt(orders?.length)
            end = dataLen + (page * 100)
            start = end > 0 ? end - dataLen + 1 : 0
        } else {
            end = orders?.length || 0
            start = end > 0 ? 1 : 0
        }

        start = cm?.translate(start, cm?.language, true)
        end = cm?.translate(end, cm?.language, true)
        count = cm?.translate(backupOrders?.count || 0, cm?.language, true)

        setPageRange(start + " - " + end + " / " + count)

    }, [orders, backupOrders?.next, backupOrders?.previous])

    return (
        <div className={"col-sm-12 col-md-10 mx-auto my-4 px-2 px-md-0"}>
            <div className="d-flex flex-column">

                <div className={"col-sm-12 mt-3 bg-success bg-opacity-10 rounded-2 border border-success border-opacity-25 p-2 d-flex flex-column flex-md-row align-items-center justify-content-between"}>
                    <div className="input-group w-auto">
                        <input type="text" name="search" className="form-control w-auto font-size-11" placeholder={cm?.translate("Type keyword")} onKeyUp={(e) => handleSearch(e)} />
                        <button className="btn btn-sm btn-success" onClick={(e) => handleSearchClicked(e)} title={cm?.translate("Search")}><BsSearch className="" /> </button>
                    </div>
                    <div className="mt-2 mt-md-0">
                        <button className={(orderDirection === "sent" ? "btn-success" : "btn-outline-success") + " btn btn-sm rounded-0"} onClick={e => handleChangeOrderDirection("sent")}>{cm?.translate("Sent Orders")}</button>
                        {auth?.authUser?.user_profile?.identification_doc && auth?.authUser?.user_profile?.is_approved && <button className={(orderDirection === "received" ? "btn-success" : "btn-outline-success") + " btn btn-sm rounded-0"} style={{ marginLeft: -1 }} onClick={e => handleChangeOrderDirection("received")}>{cm?.translate("Received Orders")}</button>}
                    </div>
                </div>
                <div className={"col-sm-12 my-4"}>
                    <div className="d-flex justify-content-end align-items-center">
                        <span className="d-block text-success font-size-10 text-end px-1">{pageRange}</span>
                    </div>
                    <Accordion className="mt-2">
                        {renderOrders()}
                    </Accordion>
                </div>
                <div className={(backupOrders?.previous ? "justify-content-between" : "justify-content-end") + " d-flex"}>
                    {backupOrders?.previous && <button className="btn btn-light d-flex align-items-center rounded-0" onClick={handlePrev}><BsChevronCompactLeft />
                        {cm?.translate("Previous")}</button>}
                    {backupOrders?.next && <button className="btn btn-light d-flex align-items-center rounded-0" onClick={handleNext}>{cm?.translate("Next")} <BsChevronCompactRight />
                    </button>}
                </div>

            </div>
        </div >
    );
};
export default Orders;
