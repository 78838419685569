import { useEffect, useState } from "react"
import { useCommon } from "../../hooks/useCommon";
import { useApi } from "../../hooks/useApi";
import useAlert from "../../hooks/useAlert";
import { getAge } from "../../utils/utils";
import Pagination from "../Pagination";
import { BsSearch } from "react-icons/bs";
import ValuePill from "../ValuePill";
import { useAuth } from "../../hooks/useAuth";
import SendMail from "../modals/SendMail";
import SendSms from "../modals/SendSms";
import Swal from "sweetalert2";

const AdminMembers = () => {
    const [members, setMembers] = useState([])
    const [selectedMemberForMail, setSelectedMemberForMail] = useState({});
    const [selectedMemberForSms, setSelectedMemberForSms] = useState({});
    const [showMailsToLeads, setShowMailsToLeads] = useState({});
    const [showSmsToLeads, setShowSmsToLeads] = useState({});
    const [currentPage, setCurrentPage] = useState(1)
    const [pageRange, setPageRange] = useState("")
    const [search, setSearch] = useState("")


    const cm = useCommon()
    const api = useApi()
    const alert = useAlert()
    const auth = useAuth()

    const getAllMembers = async (params = {}) => {
        try {
            cm.showLoader(true, "Loading..")
            const res = await api.getMembers(params)
            if (res) {
                setMembers(res);
                cm.showLoader(false)
            }
        } catch (err) {
            alert.show(err?.response?.data?.msg || err, "error")
            cm.showLoader(false)
        }
    };

    useEffect(() => {
        getAllMembers()
    }, [])

    const renderAge = (dob) => {
        return dob ? getAge(dob) : ""
    }

    const renderGender = (gender) => {
        return gender === "m" ? "Male" : gender === "f" ? "Female" : gender === "o" ? "Other" : ""
    }


    const callSuspendMember = async (alias_id) => {
        try {
            cm.showLoader(true, "Suspending..");
            const res = await api.suspendMember(alias_id);
            if (res) {
                alert.show("Member is suspended", "success");
                const ms = members?.results?.map(m => {
                    if (m?.alias_id === res?.alias_id) {
                        return res;
                    } else {
                        return m
                    }
                })

                setMembers({ ...members, results: ms })
            }
            cm.showLoader(false);
        } catch (err) {
            cm.showLoader(false);
            alert.show(err?.response?.data?.msg || err, "error");
        }
    }

    const callActivateMember = async (alias_id) => {
        try {
            cm.showLoader(true, "Activating..");
            const res = await api.activateMember(alias_id);
            if (res) {
                alert.show("Member is activated", "success");
                const ms = members?.results?.map(m => {
                    if (m?.alias_id === res?.alias_id) {
                        return res;
                    } else {
                        return m
                    }
                })

                setMembers({ ...members, results: ms })
            }
            cm.showLoader(false);
        } catch (err) {
            cm.showLoader(false);
            alert.show(err?.response?.data?.msg || err, "error");
        }
    }

    const callSuspendBusinessAccount = async (alias_id) => {
        try {
            cm.showLoader(true, "Suspending..");
            const res = await api.updateUserProfile(alias_id, { "is_approved": false });
            if (res) {
                alert.show("Business account is suspended", "success");
                const ms = members?.results?.map(m => {
                    if (m?.alias_id === alias_id) {
                        return { ...m, user_profile: res };
                    } else {
                        return m
                    }
                })

                setMembers({ ...members, results: ms })
            }
            cm.showLoader(false);
        } catch (err) {
            cm.showLoader(false);
            alert.show(err?.response?.data?.msg || err, "error");
        }
    }

    const callActivateBusinessAccount = async (alias_id) => {
        try {
            cm.showLoader(true, "Activating..");
            const res = await api.updateUserProfile(alias_id, { "is_approved": true });
            if (res) {
                alert.show("Business account is activated", "success");
                const ms = members?.results?.map(m => {
                    if (m?.alias_id === alias_id) {
                        return { ...m, user_profile: res };
                    } else {
                        return m
                    }
                })

                setMembers({ ...members, results: ms })
            }
            cm.showLoader(false);
        } catch (err) {
            cm.showLoader(false);
            alert.show(err?.response?.data?.msg || err, "error");
        }
    }

    const callDeleteMember = async (alias_id) => {
        Swal.fire({
            html: "Are you sure?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, delete it!",
            customClass: {
                confirmButton: "btn btn-sm btn-danger",
                cancelButton: "btn btn-sm btn-secondary ms-2",
            }, buttonsStyling: false
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    cm.showLoader(true, "Deleting..");
                    const res = await api.deleteMember(alias_id);
                    if (res) {
                        alert.show("Member was deleted", "success");
                        const ms = members?.results?.filter(m => m.alias_id !== alias_id)
                        setMembers({ ...members, results: ms })
                    }
                    cm.showLoader(false);
                } catch (err) {
                    cm.showLoader(false);
                    alert.show(err?.response?.data?.msg || err, "error");
                }
            }
        });

    }

    const renderMembers = () => {
        const res = members?.results?.map((m, ix) => <div key={"member_" + ix} className="col-sm-12 mb-3">
            <div className="border">
                <div className="bg-white p-2 d-flex flex-column flex-md-row">
                    <div className="mb-2">
                        <div className="d-flex flex-wrap">
                            <ValuePill title="ID" value={<a href={"members/" + m?.alias_id} target="_blank" rel="noreferrer" className="link-primary text-decoration-none font-size-11">{m.email_or_phone}</a>} />
                            <ValuePill title="Name" value={m.user_profile?.name} />
                            <ValuePill title="Age" value={renderAge(m?.user_profile?.dob)} />
                            <ValuePill title="Gender" value={renderGender(m?.user_profile?.gender)} />
                            <ValuePill title="Company" value={m.user_profile?.company_name} />
                            <ValuePill title={m?.email_or_phone.includes("@") ? "Phone" : "Email"} value={m.user_profile?.email_or_phone} />
                            <ValuePill title="Web Link" value={m.user_profile?.web_link} />
                            <ValuePill title="Location" value={m.user_profile?.location?.name} />
                            <ValuePill title="Address" value={m.user_profile?.address} />
                            <ValuePill title="Business Account" value={m.user_profile?.is_approved ? "Approved" : "Pending"} titleColorClass={m.user_profile?.is_approved ? "bg-success bg-opacity-25" : "bg-danger bg-opacity-25"} />
                            <ValuePill title="Account" value={m?.is_active ? "Active" : "Suspended"} titleColorClass={m?.is_active ? "bg-success bg-opacity-25" : "bg-danger bg-opacity-25"} />
                        </div>
                    </div>
                    <div className="d-flex flex-column">
                        <img src={m.user_profile?.profile_image ? (process.env.REACT_APP_MEDIA_URL + m.user_profile?.profile_image) : "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="} alt="identification_doc" style={{ width: 72 }} />
                        <img src={m.user_profile?.identification_doc ? (process.env.REACT_APP_MEDIA_URL + m.user_profile?.identification_doc) : "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="} alt="identification_doc" className="mt-2" style={{ width: 72 }} />
                    </div>
                </div>
                <div className="bg-light p-2 d-flex flex-row justify-content-end rounded-bottom">
                    <button className="btn btn-sm btn-white" onClick={e => setSelectedMemberForMail(m)}>Send Mail</button>
                    <button className="btn btn-sm btn-white" onClick={e => setSelectedMemberForSms(m)}>Send SMS</button>
                    {m?.is_active && <button className="btn btn-sm btn-white" onClick={e => callSuspendMember(m?.alias_id)}>Main Account Suspend</button>}
                    {!m?.is_active && <button className="btn btn-sm btn-white" onClick={e => callActivateMember(m?.alias_id)}>Main Account Activate</button>}
                    {m?.user_profile?.is_approved && <button className="btn btn-sm btn-white" onClick={e => callSuspendBusinessAccount(m?.alias_id)}>Business Account Suspend</button>}
                    {!m?.user_profile?.is_approved && <button className="btn btn-sm btn-white" onClick={e => callActivateBusinessAccount(m?.alias_id)}>Business Account Activate</button>}
                    <button className="btn btn-sm btn-white" onClick={e => callDeleteMember(m?.alias_id)} >Delete</button>
                </div>
            </div>
        </div>
        )
        if (res?.length) {
            return res;
        } else {
            return "There is no member"
        }
    }
    const onChangePage = (e) => {
        getAllMembers({ page: e.target.value });
        setCurrentPage(e.target.value)
    }

    const handleSearch = (e) => {
        setSearch(e.target.value)
        if (e.key === 'Enter') {
            getAllMembers({ search: e.target.value });
        }
    }

    const handleSearchClicked = (e) => {
        getAllMembers({ search: search });
    }

    const extractEmail = (user) => {
        if (user?.email_or_phone?.includes("@")) {
            return user?.email_or_phone;
        } else if (user?.user_profile?.email_or_phone?.includes("@")) {
            return user?.user_profile?.email_or_phone;
        } else {
            alert.show("Email address was found!", "error");
            return ""
        }
    }

    const extractPhone = (user) => {
        if (!user?.email_or_phone?.includes("@")) {
            return user?.email_or_phone;
        } else if (!user?.user_profile?.email_or_phone?.includes("@")) {
            return user?.user_profile?.email_or_phone;
        } else {
            alert.show("Valid Phone number was found!", "error");
            return ""
        }
    }

    const handleClose = () => {
        setSelectedMemberForMail({})
        setSelectedMemberForSms({})
    }


    return <>
        <div className="mt-2 mt-md-0 mb-3 d-flex flex-row justify-content-between align-items-center rounded-2 bg-success bg-opacity-10 border border-success border-opacity-25 p-2 ">
            <div className="input-group w-auto">
                <input type="text" name="search" className="form-control w-auto font-size-11" placeholder={cm?.translate("Type keyword")} onKeyUp={e => handleSearch(e)} />
                <button className="btn btn-sm btn-success rounded-end" title={cm?.translate("Search")}><BsSearch className="fs-6" onClick={e => handleSearchClicked(e)} /> </button>
            </div>
        </div>
        {renderMembers()}
        <div className="mt-4 mb-5">
            <Pagination totalItems={members?.count} onChangePage={onChangePage} currentPage={currentPage} />
        </div>
        {Object.keys(selectedMemberForMail).length > 0 && <SendMail receiver={extractEmail(selectedMemberForMail)} sender={extractEmail(auth?.authUser)} handleClose={handleClose} adminMessage={true} size={"lg"} />}
        {Object.keys(selectedMemberForSms).length > 0 && <SendSms receiver={extractPhone(selectedMemberForSms)} handleClose={handleClose} />}
    </>


}
export default AdminMembers