import { useEffect, useState } from "react";
import ImageUploader from "../ImageUploader";
import SaveButton from "../SaveButton";
import { Form } from "react-bootstrap";
import PreviewMediaFile from "../PreviewMediaFile";
import { BsCheck2Square, BsClockHistory } from "react-icons/bs";
import { useAuth } from "../../hooks/useAuth";
import { useApi } from "../../hooks/useApi";
import { useCommon } from "../../hooks/useCommon";
import useAlert from "../../hooks/useAlert";
import useRenewAuth from "../../hooks/useRenewAuth";


const Profile = () => {
    const [userProfile, setUserProfile] = useState({});
    const [profileImage, setProfileImage] = useState(null);
    const [idImage, setIdImage] = useState(null);
    const [locations, setLocations] = useState([]);
    const [activeProfessionalAccount, setActiveProfessionalAccount] =
        useState(false);
    const [profileChanges, setProfileChanges] = useState({});
    const auth = useAuth();
    const api = useApi();
    const cm = useCommon();
    const alert = useAlert();
    const renewAuth = useRenewAuth();


    const activateSave = () => {
        if (
            Object.keys(profileChanges).length > 0 && userProfile?.name
        ) {
            return true;
        }
        return false;
    };

    const toggleProfessionalAcc = (e) => {
        setActiveProfessionalAccount(
            !activeProfessionalAccount
        )
        if (activeProfessionalAccount) {
            setProfileChanges({ ...profileChanges, location: null, company_name: null, web_link: null, identification_doc: null, is_approved: false })
        } else {
            setProfileChanges({ ...profileChanges, location: userProfile?.location, company_name: userProfile?.company_name, web_link: userProfile?.web_link, identification_doc: userProfile?.identification_doc, is_approved: userProfile?.is_approved })
        }
    }

    const removeProfilePhoto = () => {
        setUserProfile({ ...userProfile, profile_image: null });
        setProfileChanges({ ...profileChanges, profile_image: null });
    };

    const removeIdentificitaionDoc = () => {
        setUserProfile({ ...userProfile, identification_doc: null });
        setProfileChanges({ ...profileChanges, identification_doc: null });
    };

    const handleFormInput = (e) => {
        const { name, value } = e.target;
        let profile = { ...userProfile }
        if (name === "location") {
            profile = {
                ...userProfile,
                [name]: locations?.find((l) => l.id === parseInt(value)),
            };
        } else if (name === "email_or_phone" && auth?.authUser.email_or_phone.includes("@")) {
            if (/^\d+$/.test(value) || value === "") {
                profile = { ...userProfile, [name]: value };
            }
        }
        else {
            profile = { ...userProfile, [name]: value };
        }
        setUserProfile(profile);
        setProfileChanges({ ...profileChanges, [name]: profile[name] })
    };

    const getLocationsFromApi = async () => {
        try {
            const res = await api.getLocations();
            setLocations(res);
        } catch (err) {
            alert.show(err?.response?.data?.msg, "error");
        } finally {
        }
    };

    useEffect(() => {
        getLocationsFromApi();
        renewAuth.renew();
    }, []);

    useEffect(() => {
        setUserProfile(auth.authUser?.user_profile);
        if (auth.authUser?.user_profile?.identification_doc) {
            setActiveProfessionalAccount(true)
        }
    }, [auth.authUser]);


    const handleUploadProfileImage = (e) => {
        if (e.length > 0) {
            setProfileImage(e[0])
            setProfileChanges({ ...profileChanges, profile_image: e[0]?.base64 })
        } else {
            setProfileImage(null)
            let state = {
                ...profileChanges
            };
            delete state.profile_image
            setProfileChanges(state)
        }
    }

    const handleUploadIdImage = (e) => {
        if (e.length > 0) {
            setIdImage(e[0])
            setProfileChanges({ ...profileChanges, identification_doc: e[0]?.base64 })
        } else {
            setIdImage(null)
            let state = {
                ...profileChanges
            };
            delete state.identification_doc
            setProfileChanges(state)
        }
    }

    const handleSave = async () => {
        try {
            cm?.showLoader(true, "Saving..")
            const res = await api.updateUserProfile(auth.authUser?.alias_id, profileChanges);
            if (res) {
                const au = { ...auth.authUser, user_profile: res };
                auth.updateAuthUser(au);
                setProfileChanges({});
                cm?.showLoader(false)
                return true;
            }
        } catch (err) {
            cm?.showLoader(false)
            alert.show(err?.response?.data?.msg || err, "error")
        }
    };

    const renderLocation = () => {
        return locations?.map((l) => (
            <option key={"loc_" + l.id} value={l.id}>
                {l.name}
            </option>
        ));
    };



    return (
        <div
            className={"col-sm-12 col-md-8 mx-auto my-2 my-md-4  px-3 px-md-0"}
        >
            <div className="d-flex">

                {userProfile?.profile_image && (
                    <div className="me-2">
                        <PreviewMediaFile
                            src={
                                userProfile?.profile_image
                            }
                            removeFromPreview={removeProfilePhoto}
                        />
                    </div>
                )}
                <div>
                    <h3 className="mb-0">
                        {auth.authUser?.user_profile?.company_name || auth.authUser?.user_profile?.name || auth.authUser?.email_or_phone}
                    </h3>
                    {!auth.authUser?.name && <span className="font-size-10 text-danger">{auth.authUser?.email_or_phone}</span>}
                </div>
            </div>
            <div className="mt-4">
                <form>
                    <div className="row m-0 d-flex align-items-center mb-1 bg-light p-3 px-0">
                        <div className="col-3">
                            <span>{cm?.translate("Profile Photo")}</span>
                        </div>
                        <div className="col d-flex justify-content-start align-items-center">
                            <ImageUploader
                                setNewFiles={handleUploadProfileImage}
                                label={cm?.translate("Select Profile Photo")}
                                multipleUpload="false"
                                width="auto"
                            />
                        </div>
                    </div>

                    <div className="row m-0 d-flex align-items-center mb-1 bg-light p-3 px-0">
                        <div className="col-3">
                            <span>{cm?.translate("Name")} *</span>
                        </div>
                        <div className="col">
                            <input
                                type="text"
                                name="name"
                                className="form-control"
                                value={userProfile?.name || ""}
                                onChange={handleFormInput}
                                required={true}
                            />
                        </div>
                    </div>

                    <div className="row m-0 d-flex align-items-center mb-1 bg-light p-3 px-0">
                        <div className="col-3">
                            <span>{cm?.translate("Gender")}</span>
                        </div>
                        <div className="col">
                            <select
                                name="gender"
                                className="form-select"
                                value={userProfile?.gender || ""}
                                onChange={handleFormInput}
                            >
                                <option value="">{cm?.translate("Select Gender")}</option>
                                <option value="m">{cm?.translate("Male")}</option>
                                <option value="f">{cm?.translate("Female")}</option>
                                <option value="o">{cm?.translate("Other")}</option>
                            </select>
                        </div>
                    </div>

                    <div className="row m-0 d-flex align-items-center mb-1 bg-light p-3 px-0">
                        <div className="col-3">
                            <span>{cm?.translate("Date of Birth")}</span>
                        </div>
                        <div className="col">
                            <input
                                type="date"
                                name="dob"
                                className="form-control"
                                value={userProfile?.dob || ""}
                                onChange={handleFormInput}
                            />
                        </div>
                    </div>


                    <div className="row m-0 d-flex align-items-center mb-1 bg-light p-3 px-0">
                        <div className="col-3">
                            {auth.authUser?.email_or_phone?.includes("@") ?
                                <>
                                    <span>{cm?.translate("Phone")}</span>
                                </>
                                : <span>{cm?.translate("Email")}</span>
                            }
                        </div>
                        <div className="col">
                            <input
                                type="text"
                                name="email_or_phone"
                                maxLength={auth.authUser?.email_or_phone?.includes("@") ? 14 : 100}
                                value={userProfile?.email_or_phone || ""}
                                className="form-control"
                                onChange={handleFormInput}
                            />
                        </div>
                    </div>

                    <div className="row m-0 d-flex align-items-center mb-1 bg-light p-3 px-0">
                        <div className="col-3">
                            <span>{cm?.translate("Address")}</span>
                        </div>
                        <div className="col">
                            <textarea
                                type="text"
                                rows={2}
                                name="address"
                                value={userProfile?.address || ""}
                                className="form-control"
                                onChange={handleFormInput}
                            ></textarea>
                        </div>
                    </div>
                    <div className={(activeProfessionalAccount ? "bg-success" : "bg-secondary") + " row m-0 d-flex align-items-center mb-1 p-2 px-0 bg-opacity-25"}>
                        <div className="col text-center d-flex justify-content-center align-items-center ">
                            <Form.Check
                                name="professionalAccount"
                                type="switch"
                                className="fs-4"
                                onChange={toggleProfessionalAcc}
                                checked={activeProfessionalAccount}
                                value={activeProfessionalAccount}
                            />

                            {activeProfessionalAccount ? <span className="ms-1">{cm?.translate("Switch to disable professional account")}</span> : <span className="ms-1">{cm?.translate("Switch to enable professional account")}</span>}

                        </div>
                    </div>
                    {activeProfessionalAccount && (
                        <div>
                            <div className="row m-0 d-flex align-items-center mb-1 bg-light p-3 px-0">
                                <div className="col-3">
                                    <span>{cm?.translate("Location")}</span>
                                </div>
                                <div className="col">
                                    <select
                                        name="location"
                                        className="form-select"
                                        value={userProfile?.location?.id || ""}
                                        onChange={handleFormInput}
                                    >
                                        <option value="">{cm?.translate("Select Location")}</option>
                                        {renderLocation()}
                                    </select>
                                </div>
                            </div>
                            <div className="row m-0 d-flex align-items-center mb-1 bg-light p-3 px-0">
                                <div className="col-3">
                                    <span>{cm?.translate("Company or organization name")}</span>
                                </div>
                                <div className="col">
                                    <input
                                        type="text"
                                        name="company_name"
                                        value={userProfile?.company_name || ""}
                                        className="form-control"
                                        onChange={handleFormInput}
                                    />
                                </div>
                            </div>
                            <div className="row m-0 d-flex align-items-center mb-1 bg-light p-3 px-0">
                                <div className="col-3">
                                    <span>{cm?.translate("Website link")}</span>
                                </div>
                                <div className="col">
                                    <input
                                        type="text"
                                        name="web_link"
                                        value={userProfile?.web_link || ""}
                                        className="form-control"
                                        onChange={handleFormInput}
                                    />
                                </div>
                            </div>
                            <div className="row m-0 d-flex align-items-center mb-1 bg-light p-3 px-0">
                                <div className="col-3">
                                    <span>
                                        {cm?.translate("NID or Trade license or Passport copy")}
                                    </span>
                                </div>
                                <div className="col d-flex justify-content-start align-items-center">
                                    {userProfile?.identification_doc && (
                                        <PreviewMediaFile
                                            src={
                                                userProfile?.identification_doc
                                            }
                                            removeFromPreview={
                                                removeIdentificitaionDoc
                                            }
                                        />
                                    )}
                                    <ImageUploader
                                        setNewFiles={handleUploadIdImage}
                                        label={userProfile?.identification_doc ? cm?.translate("Change Document Photo") : cm?.translate("Upload Document Photo")}
                                        multipleUpload="false"
                                        width="auto"
                                    />
                                </div>
                            </div>
                            <div className="row m-0 d-flex align-items-center mb-1 bg-light p-3 px-0">
                                <div className="col-3">
                                    <span>
                                        {cm?.translate("Professional account status")}
                                    </span>
                                </div>
                                <div className="col d-flex justify-content-start align-items-center">
                                    {(userProfile?.is_approved && userProfile?.identification_doc) ? <><BsCheck2Square className="fs-4 me-2 text-success" /> <span className="text-success">{cm?.translate("Approved")}</span></> : userProfile?.identification_doc ? <><BsClockHistory className="fs-4 me-2" /> <span className="">{cm?.translate("Applied and waiting for approval")} <span className="font-size-9">({cm?.translate("Usually it takes 30 minutes to 24 hours to approve, will notify you in time")})</span></span></> : <span className="text-danger">{cm?.translate("Identification document is required to activate professional account")}</span>}
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="row m-0 d-flex align-items-center mb-1 bg-light p-5 px-0">
                        <div className="col d-flex justify-content-center">
                            {
                                <SaveButton
                                    handleSave={handleSave}
                                    disabled={!activateSave()}
                                    label={cm?.translate("Save Changes")}
                                />
                            }
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};
export default Profile;
