import React, { useEffect, useState } from "react";
import ImageUploader from "../ImageUploader";
import { BsImages } from "react-icons/bs";
import { useApi } from "../../hooks/useApi";
import { useCommon } from "../../hooks/useCommon";
import useAlert from "../../hooks/useAlert";

const InquiryReply = ({ inquiry, onReply }) => {
  const [mediaFiles, setMediaFiles] = useState([]);
  const [message, setMessage] = useState("");

  const api = useApi();
  const cm = useCommon();
  const alert = useAlert();

  const handleSend = async () => {
    const data = {
      "message": mediaFiles.length > 0 ? `<img src="${mediaFiles[0]?.base64}"/><br/>${message.trim()}` : message.trim(),
      "inquiry": inquiry?.id
    }
    try {
      cm.showLoader(true, "Sending..");
      const res = await api.sendReply(data);
      if (res) {
        onReply(res?.inquiry)
        setMessage("");
        setMediaFiles([]);
      }
      cm.showLoader(false);
    } catch (err) {
      alert.show(err?.response?.data?.msg || err, "error");
      cm.showLoader(false);
    }
  };


  return (
    <div className="d-flex mt-2">
      <ImageUploader setNewFiles={setMediaFiles} clearPreview={mediaFiles.length <= 0} multipleUpload="false" label="" width="" icon={<BsImages className="fs-4" />} previewHeight={52} previewWidth={52} />
      <textarea
        placeholder={cm?.translate("Type a message")}
        type="text"
        value={message}
        name="text"
        className="form-control ms-2"
        rows={2}
        onChange={(e) => setMessage(e.target.value)}
      ></textarea>
      <button
        onClick={handleSend}
        className="btn btn-sm btn-dark ms-2"
        disabled={!message}
      >
        {cm?.translate("Send")}
      </button>
    </div>
  );
};
export default InquiryReply;
