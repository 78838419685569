import React, { useState } from "react";
import { useEffect } from "react";
import PostTemplateForMyPage from "./PostTemplateForMyPage";
import UserProfile from "../UserProfile";
import CreateAd from "./CreateAd";
import { useApi } from "../../hooks/useApi"
import { useAuth } from "../../hooks/useAuth"
import { useCommon } from "../../hooks/useCommon";
import { BsSearch } from "react-icons/bs";
import useAlert from "../../hooks/useAlert";
import Pagination from "../Pagination";

const MyAds = () => {
    const [backupAds, setBackupAds] = useState({})
    const [currentPage, setCurrentPage] = useState(1)
    const [search, setSearch] = useState("")

    const api = useApi();
    const auth = useAuth();
    const cm = useCommon()
    const alert = useAlert()


    const getAdsFromApi = async (params) => {
        try {
            cm.showLoader(true);
            const res = await api?.getUserAds(auth.authUser?.alias_id, params);
            if (res) {
                cm?.setMyAds(res?.results);
                setBackupAds(res);
                cm.showLoader(false);
            }

        } catch (err) {
            alert.show(err?.response?.data?.msg || err, "error")
            cm.showLoader(false);
        }
    };
    const getAdDetailFromApi = async (aliasId) => {
        try {
            cm.showLoader(true);
            const res = await api?.getAdDetail(aliasId);
            if (res) {
                const filterAd = cm?.myAds.filter(a => a.alias_id !== res?.alias_id)
                const newList = [res, ...filterAd]
                cm?.setMyAds(newList);
                setBackupAds({ ...backupAds, results: newList });
                cm.showLoader(false);
            }

        } catch (err) {
            alert.show(err?.response?.data?.msg || err, "error")
            cm.showLoader(false);
        }
    };

    const refreshAd = (post, action = "update") => {
        const fAds = cm?.myAds?.filter(f => f.alias_id !== post?.alias_id);
        if (action === "update") {
            const newAds = [post].concat(fAds)
            cm?.setMyAds(newAds)
        } else if (action === "delete") {
            cm?.setMyAds(fAds)
        }
        cm?.setAdsCache({});
    }


    useEffect(() => {
        getAdsFromApi();
    }, [])

    const handleSearch = async (e) => {
        setSearch(e.target.value)
        if (e.key === 'Enter') {
            getAdsFromApi({ search: e.target.value });
        }
    }

    const handleSearchClicked = async (e) => {
        getAdsFromApi({ search: search });
    }

    const renderAds = () => {
        return cm?.myAds?.map((p, i) =>
            <PostTemplateForMyPage key={"post_" + i} post={p} refreshAd={refreshAd} />);
    };

    const handlePrev = () => {
        if (backupAds?.previous) {
            const page = backupAds?.previous.split("=")[1];
            getAdsFromApi({ page: page });
        }
    }

    const handleNext = () => {
        if (backupAds?.next) {
            const page = backupAds?.next.split("=")[1];
            getAdsFromApi({ page: page });
        }
    }

    const decodeSearchParams = (searchParams) => {
        return [...searchParams.entries()].reduce((acc, [key, val]) => {
            return {
                ...acc,
                [key]: val
            };
        }, {});
    };

    // useEffect(() => {
    //     let start, end, count = "";
    //     if (backupAds?.next !== null && backupAds?.next !== undefined) {
    //         const params = decodeSearchParams(new URL(backupAds?.next).searchParams);
    //         const nextPage = parseInt(params["page"]);
    //         const page = nextPage - 2
    //         end = (page + 1) * cm?.myAds?.length
    //         start = end > 0 ? end - cm?.myAds?.length + 1 : 0
    //     } else if (backupAds?.previous) {
    //         const params = decodeSearchParams(new URL(backupAds?.previous).searchParams);
    //         const prevPage = parseInt(params["page"] || 1);
    //         const page = prevPage
    //         const dataLen = parseInt(cm?.myAds?.length)
    //         end = dataLen + (page * 100)
    //         start = end > 0 ? end - dataLen + 1 : 0
    //     } else {
    //         end = cm?.myAds?.length || 0
    //         start = end > 0 ? 1 : 0
    //     }

    //     start = cm?.translate(start, cm?.language, true)
    //     end = cm?.translate(end, cm?.language, true)
    //     count = cm?.translate(backupAds?.count || 0, cm?.language, true)

    //     setPageRange(start + " - " + end + " / " + (count || 0))

    // }, [cm?.myAds, backupAds?.next, backupAds?.previous])



    useEffect(() => {
        Object.keys(cm?.refreshAdInMyAds).length && getAdDetailFromApi(cm?.refreshAdInMyAds?.alias_id)
    }, [cm?.refreshAdInMyAds])

    const onChangePage = (e) => {
        getAdsFromApi({ page: e.target.value });
        setCurrentPage(e.target.value)
    }


    return (
        <div className={"col-sm-12 col-md-10 mx-auto my-4 px-2 px-md-0"}>
            <div className="d-flex flex-column">
                <div className={"col-sm-12"}>
                    {auth.authUser && <UserProfile user={auth.authUser} />}
                </div>
                <div className={"col-sm-12 mt-3 bg-success bg-opacity-10 rounded-2 border border-success border-opacity-25 p-2 d-flex align-items-center justify-content-between"}>
                    <div className="input-group w-auto">
                        <input type="text" name="search" className="form-control w-auto font-size-11" placeholder={cm?.translate("Type keyword")} onKeyUp={(e) => handleSearch(e)} />
                        <button className="btn btn-sm btn-success" onClick={(e) => handleSearchClicked(e)} title={cm?.translate("Search")}><BsSearch className="fs-5" />                        </button>
                    </div>
                    <div>
                        <CreateAd buttonWithIcon={false} btnTitle={"Create Ad."} redirectPath="/mypage/ads" />
                    </div>
                </div>
                <div className={"col-sm-12 my-4"}>
                    {/* <div className="d-flex justify-content-end align-items-center">
                        <span className="d-block text-success font-size-10 text-end px-1">{pageRange}</span>
                    </div> */}
                    <div className="row m-0 g-2 " >{renderAds()}</div>
                </div>
                {/* <div className={(backupAds?.previous ? "justify-content-between" : "justify-content-end") + " d-flex"}>
                    {backupAds?.previous && <button className="btn btn-light d-flex align-items-center rounded-0" onClick={handlePrev}><BsChevronCompactLeft />
                        {cm?.translate("Previous")}</button>}
                    {backupAds?.next && <button className="btn btn-light d-flex align-items-center rounded-0" onClick={handleNext}>{cm?.translate("Next")} <BsChevronCompactRight />
                    </button>}
                </div> */}

                <div className="mt-4 mb-5">
                    <Pagination totalItems={backupAds?.count} onChangePage={onChangePage} currentPage={currentPage} />
                </div>

            </div>
        </div >
    );
};
export default MyAds;
