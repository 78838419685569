import { createContext, useContext, useEffect, useState } from "react";
import { useLocalStorage } from "./useLocalStorage";

const DataContext = createContext({});

export const DataProvider = (props) => {
    const [error, setError] = useState("");
    const [showLoading, setShowLoading] = useState({});
    const [showMediaCarousel, setShowMediaCarousel] = useState(false);
    const [ads, setAds] = useState([])
    const [myAds, setMyAds] = useState([])
    const [refreshAdInMyAds, setRefreshAdInMyAds] = useState({})
    const [adsCache, setAdsCache] = useLocalStorage("adsCache", {})
    const [categories, setCategories] = useState([])
    const [categoryGroups, setCategoryGroups] = useLocalStorage("categoryGroups", [])
    const [locations, setLocations] = useLocalStorage("locations", [])
    const [selectedCategoryGroup, setSelectedCategoryGroup] = useState({})
    const [selectedCategory, setSelectedCategory] = useState({})
    const [selectedLocation, setSelectedLocation] = useState({})
    const [dictionary, setDictionary] = useLocalStorage("dictionary", {})
    const [language, setLanguage] = useLocalStorage("language", "en")

    const showLoader = (show, title = "Loading") => {
        setShowLoading({ show: show, title: title });
    }

    const translate = (word, lang = language || "en", is_number = false) => {
        if (is_number) {
            return [...String(word)].map(letter => dictionary?.[letter]?.[lang] || letter).join("")
        }
        const tWord = dictionary?.[word]?.[lang] || word
        return tWord
    }

    const value = { ads, setAds, myAds, setMyAds, refreshAdInMyAds, setRefreshAdInMyAds, adsCache, setAdsCache, categories, setCategories, categoryGroups, setCategoryGroups, selectedCategoryGroup, setSelectedCategoryGroup, selectedCategory, setSelectedCategory, locations, setLocations, selectedLocation, setSelectedLocation, dictionary, setDictionary, translate, showLoading, showLoader, language, setLanguage, showMediaCarousel, setShowMediaCarousel, error, setError };

    useEffect(() => {
        if (Object.keys(adsCache).length > 0) {
            const interval = setInterval(() => {
                setAdsCache({})
            }, 1000 * 60 * 5)
            return () => clearInterval(interval)
        }
    }, [adsCache])

    return (
        <DataContext.Provider value={value}>
            {props.children}
        </DataContext.Provider>
    );
};


export const useCommon = () => {
    return useContext(DataContext);
};