
import { useEffect } from "react";
import { useApi } from "../hooks/useApi";
import { useCommon } from "../hooks/useCommon";
import Categories from "./Categories";
import CategoryGroups from "./CategoryGroups";
import Search from "./Search";
import CreateAd from "./mypage/CreateAd";
import Locations from "./Locations";
import { useSearchParams } from "react-router-dom";
import { decodeSearchParams } from "../utils/utils";
import moment from "moment";
import useAlert from "../hooks/useAlert";
import { useAuth } from "../hooks/useAuth";
import FollowerSearch from "./modals/FollowerSearch";
import FilterSelection from "./modals/FilterSelection";

const SelectionMenu = () => {
    const [searchParams] = useSearchParams();
    const api = useApi();
    const cm = useCommon();
    const alert = useAlert();
    const auth = useAuth();

    const getLocations = async () => {
        try {
            if (cm?.locations.length <= 0 || moment().date() % 15 === 0) {
                const res = await api.getLocations();
                if (res) {
                    const sortedLocation = res.sort((a, b) => {
                        if (a.name > b.name) return 1;
                        if (a.name < b.name) return -1;
                        return 0;
                    })
                    cm.setLocations(sortedLocation);
                }
            }
        } catch (err) {
            alert.show(err?.message, "error")
        }
    };

    const getCategoriesFromApi = async () => {
        try {
            const res = await api.getCategories();
            if (res) {
                cm.setCategories(res);
            }
        } catch (err) {
            alert.show(err?.message, "error")
        }
    };

    const getCategoryGroupsFromApi = async () => {
        try {
            if (cm?.categoryGroups.length <= 0 || moment().date() % 15 === 0) {
                const res = await api.getCategoryGroups();
                if (res) {
                    cm.setCategoryGroups(res.sort((a, b) => a.id - b.id));
                    cm.setSelectedCategoryGroup(res.find(c => c.id === 1));
                }
            }
        } catch (err) {
            alert.show(err?.message, "error")
        }
    };

    useEffect(() => {
        getCategoryGroupsFromApi()
        getCategoriesFromApi()
        getLocations()
    }, [])


    const getAdsFromApi = async (params) => {
        try {
            cm.showLoader(true);
            if (searchParams?.size > 0 || Object.keys(cm?.adsCache).length <= 0) {
                const res = await api.getAds(params || {})
                if (res) {
                    cm?.setAds(res);
                    cm?.showLoader(false);
                    if (searchParams?.size <= 0) {
                        cm?.setAdsCache(res);
                    }
                }
            } else {
                cm?.setAds(cm?.adsCache);
                cm?.showLoader(false)
            }
        } catch (err) {
            alert.show(err?.response?.data?.msg, "error")
            cm.showLoader(false)
        }
    };

    useEffect(() => {
        const params = decodeSearchParams(searchParams)
        getAdsFromApi(params)
    }, [searchParams])


    return <div className="col-sm-12 col-md-10 mx-auto mt-3 border border-success border-opacity-50 bg-success bg-opacity-10 rounded-3 shadow-sm">
        <div className="row m-0 p-1">
            <div className="col-sm-12 col-md-6 col-lg-6 flex-fill d-flex justify-content-center justify-content-md-start  align-items-center px-0">
                {cm?.categoryGroups?.length > 0 && <CategoryGroups />}
                {cm?.categories?.length > 0 && <Categories />}
                {cm?.locations?.length > 0 && <Locations />}
                {/* <FilterSelection /> */}

            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 d-flex flex-row flex-wrap justify-content-center justify-content-md-end align-items-center px-0" >
                <Search />
                <CreateAd />
                {auth?.authUser && <FollowerSearch title="Following" user={auth?.authUser} resource="followings" />}
            </div>
        </div>
    </div>
}
export default SelectionMenu