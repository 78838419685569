import { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import ImageUploader from "../ImageUploader";
import SaveButton from "../SaveButton";
import { useApi } from "../../hooks/useApi"
import { useCommon } from "../../hooks/useCommon";
import { useAuth } from "../../hooks/useAuth";
import useAlert from "../../hooks/useAlert";
// import AdOrderOption from "../AdOrderOption";
import MonozunaEditor from "../MonozunaEditor";
// import CreateShorts from "../modals/CreateShorts";
// import Orderable from "../modals/ordernow/Orderable";
// import ShortsOption from "../ShortsOption";

const ModifyAd = ({ post, setShowModifyModal, refreshAd }) => {
    const [show, setShow] = useState(false);
    // const [showOrderable, setShowOrderable] = useState(false)
    const [deletedMedia, setDeletedMedia] = useState([]);
    const [newMedia, setNewMedia] = useState([]);
    const [privacyTypes, setPrivacyTypes] = useState([]);
    const [data, setData] = useState({})
    const [changedData, setChangedData] = useState({})
    const [selectedCategory, setSelectedCategory] = useState([])
    const [enableSave, setEnableSave] = useState(false)

    const api = useApi();
    const cm = useCommon();
    const auth = useAuth();
    const alert = useAlert();

    const handleClose = () => {
        setData({})
        setChangedData({})
        cm.showLoader(false)
        setShow(false);
        setShowModifyModal(false);
    }

    const handleFormInput = (e) => {
        const { name, value } = e.target;

        if (name === "is_approved" && typeof value === "string") {
            setChangedData({ ...data, [name]: value === "true" ? true : false })
        } else {
            setChangedData({ ...data, [name]: value })
        }

        setChangedData({ ...changedData, [name]: value })
        setData({ ...data, ...changedData })

    }

    // const handlePrivacy = (e) => {
    //     const { name, value } = e.target;
    //     setChangedData({ ...changedData, [name]: value })

    //     const pt = privacyTypes.find(p => p?.id === value)
    //     setData({ ...data, ...changedData })

    // }

    const getPrivacyTypeFromApi = async () => {
        try {
            const res = await api.getPrivacyTypes();
            setPrivacyTypes(res);
        } catch (err) {
            alert.show(err?.message, "error");
        }
    };

    const getAdDetailFromApi = async (aliasId) => {
        try {
            cm.showLoader(true);
            const res = await api.getAdDetail(aliasId || post?.alias_id);
            if (res) {
                setData(res);
                cm.showLoader(false);
            }
        } catch (err) {
            handleClose();
            alert.show(err?.response?.data?.msg || err, "error")
        }
    };


    // const renderCategories = () => {
    //     return cm?.categories && cm?.categories?.map(c => <option key={"cat_" + c.id} value={c.id}>{c?.group?.name} - {c.name}</option>)
    // }

    const renderCategories = () => {
        const uniq = (items) => [...new Set(items)];
        const uniqueGroups = uniq(cm?.categories.map((item) => item?.group?.name));
        return uniqueGroups?.map((g, ix) => {
            return <optgroup key={g + "_" + ix} label={g} style={{ background: "#fff" }}>
                {cm?.categories && cm?.categories?.map(c => c?.group?.name === g && <option key={"cat_" + c.id} value={c?.id} >{cm?.translate(c?.name)} ({cm?.translate(c?.total_ads, cm?.language, true)})</option>)}
            </optgroup>
        })
    }

    const renderPrivacyTypes = () => {
        return privacyTypes && privacyTypes?.map(p => <option key={"pt_" + p.id} value={p.id}>{p.name}</option>)
    }

    const handleSave = async () => {
        try {
            cm.showLoader(true, "Updating..");
            const res = await api.updateAd(post?.alias_id, changedData);

            if (Object.keys(res).length) {
                cm?.setRefreshAdInMyAds({ alias_id: post?.alias_id });
                handleClose();
            }

        } catch (err) {
            setData({});
            handleClose();
            alert.show(err?.response?.data?.msg || err, "error")
        }

    }

    const getCategoriesFromApi = async () => {
        try {
            const res = await api.getCategories();
            cm.setCategories(res.sort((a, b) => a?.group?.id - b?.group?.id));
        } catch (err) {
            alert.show(err?.message, "error")
        }
    };

    const enableSaveButton = () => {
        if (changedData && Object.keys(changedData).length) {
            if (changedData?.title === "" ||
                changedData?.category === "" ||
                changedData?.description === ""
            ) {
                setEnableSave(false)
            } else {
                setEnableSave(true)
            }
        } else {
            setEnableSave(false)
        }
    }

    const handleHtmlEditorReturn = (v) => {
        setChangedData({ ...changedData, ...v })
    }

    const handleOrderable = (v) => {
        setChangedData({ ...changedData, order_option: v })
    }

    // const enableOrderableButton = () => {
    //     const orderableBtn = <>
    //         <button className="btn btn-white border border-opacity-10 me-2" onClick={e => setShowOrderable(true)}><img src={process.env.PUBLIC_URL + "/static/orderable.png"} width={100} alt="orderable" /></button>
    //         {showOrderable && <Orderable existingOrderOption={changedData?.order_option || data?.ad_order_option} setShowOrderable={setShowOrderable} handleOrderable={handleOrderable} />}
    //     </>

    //     if (data && data?.ad_order_option && "unit_price" in data?.ad_order_option) {
    //         return
    //         // } else if (changedData && changedData?.order_option && "unit_price" in changedData?.order_option) {
    //         //     return
    //     } else {
    //         return orderableBtn
    //     }
    // }

    // const enableOrderable = () => {
    //     const orderable = <Form.Group className="mb-2 mt-3 pt-3 mb-4">
    //         <AdOrderOption adAliasId={data?.alias_id} adOrderOption={changedData?.order_option || data?.ad_order_option} handleOrderable={handleOrderable} />
    //     </Form.Group>

    //     if (data && data?.ad_order_option?.unit_price) {
    //         return orderable
    //     }
    //     // else if (changedData && changedData?.order_option && "unit_price" in changedData?.order_option) {
    //     //     return orderable
    //     // }
    //     else {
    //         return
    //     }
    // }

    // const enableShortsButton = () => {
    //     const shortsBtn = <>
    //         <button className="btn btn-white border border-opacity-10 d-flex align-items-center" onClick={(e) => setShowCreateShorts(true)}><img src={process.env.PUBLIC_URL + "/static/shorts.png"} height={30} alt="shorts_logo" className="ms-2" /></button>
    //         {showCreateShorts && <CreateShorts ad={data} setShowCreateShorts={setShowCreateShorts} />}
    //     </>

    //     if (data && data?.shorts_option && "duration" in data?.shorts_option) {
    //         return
    //     } else {
    //         return shortsBtn
    //     }
    // }

    // const enableShorts = () => {
    //     const shorts = <Form.Group className="mb-3 mt-2">
    //         {Object.keys(data).length && <ShortsOption adAliasId={data?.alias_id} shortsOption={{ ...data?.shorts_option, image: data?.media[0]?.media_url_resized_shorts || "" }} />}
    //     </Form.Group>

    //     if (Object.keys(data).length && data?.shorts_option && "duration" in data?.shorts_option) {
    //         return shorts
    //     } else {
    //         return
    //     }
    // }

    useEffect(() => {
        getCategoriesFromApi();
        getPrivacyTypeFromApi();
        getAdDetailFromApi();
        setShow(true);
    }, [])

    // useEffect(() => {
    //     if ("alias_id" in cm?.refreshAdInMyAds) {
    //         getAdDetailFromApi();
    //         setChangedData({ ...changedData })
    //     }

    // }, [cm?.refreshAdInMyAds])


    useEffect(() => {
        newMedia.length > 0 && setChangedData({ ...changedData, new_media: newMedia })
    }, [newMedia])

    useEffect(() => {
        deletedMedia.length > 0 && setChangedData({ ...changedData, deleted_media: deletedMedia })
    }, [deletedMedia])

    useEffect(() => {
        // console.log("data", data)
        setSelectedCategory(cm?.categories.find(c => parseInt(c?.id) === parseInt(data?.category?.id || changedData?.category)))
    }, [data])

    useEffect(() => {
        // console.log("changed data", changedData)
        setSelectedCategory(cm?.categories.find(c => parseInt(c?.id) === parseInt(data?.category?.id || changedData?.category)))
        enableSaveButton()
    }, [changedData])


    return data && <>
        <Modal size="lg" scrollable="true" show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>Modify Ad.</Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-3">
                <div className="row m-0 mb-1">
                    <div className="col-sm-12 col-md-3  d-flex align-items-center bg-light py-1">
                        {cm?.translate("Photos")}
                    </div>
                    <div className="col py-0">
                        <ImageUploader setNewFiles={setNewMedia} setDeletedExistingFile={setDeletedMedia} deletedExistingFile={deletedMedia} existingMediaFiles={data?.media} label={cm?.translate("Upload")} />
                    </div>
                </div>
                <div className="row m-0 mb-1">
                    <div className="col-sm-12 col-md-3  d-flex align-items-center bg-light py-1 position-relative">
                        {cm?.translate("Category")}
                        <span className="font-size-8 text-secondary position-absolute end-0 bottom-0 me-1">{cm?.translate("Required")}</span>
                    </div>
                    <div className="col py-1">
                        <Form.Select autoFocus name="category" value={data?.category?.id} onChange={e => handleFormInput(e)}>
                            <option value="">{cm?.translate("Select Category")} *</option>
                            {renderCategories()}
                        </Form.Select>
                    </div>
                </div>
                <div className="row m-0 mb-1">
                    <div className="col-sm-12 col-md-3  d-flex align-items-center bg-light py-1 position-relative">
                        {cm?.translate("Title")}
                        <span className="font-size-8 text-secondary position-absolute end-0 bottom-0 me-1">{cm?.translate("Required")}</span>
                    </div>
                    <div className="col py-1">
                        <Form.Control type="text" name="title" required placeholder="Write a suitable title" value={data?.title || ""} onChange={handleFormInput} />
                    </div>
                </div>
                <div className="row m-0 mb-1">
                    <div className="col-sm-12 col-md-3 d-flex align-items-start bg-light py-1 position-relative">
                        {cm?.translate("Description")}
                        <span className="font-size-8 text-secondary position-absolute end-0 bottom-0 me-1">{cm?.translate("Required")}</span>
                    </div>
                    <div className="col py-1">
                        <MonozunaEditor name="description" placeholder={cm?.translate("Write or paste item detail")} content={data?.description} updateHtmlContent={handleHtmlEditorReturn} style={{ height: 150 }} />
                    </div>
                </div>
                <div className="row m-0 mb-1">
                    <div className="col-sm-12 col-md-3 d-flex align-items-center bg-light py-1">
                        {cm?.translate("Privacy")}
                    </div>
                    <div className="col py-1">
                        <select name="privacy_type" value={changedData?.privacy_type || data?.privacy_type || data?.privacy?.id} onChange={handleFormInput} className="form-control">
                            {renderPrivacyTypes()}
                        </select>
                    </div>
                </div>
                {auth?.authUser?.is_superuser &&
                    <div className="row m-0 mb-1">
                        <div className="col-sm-12 col-md-3 d-flex align-items-center bg-light py-1">
                            {cm?.translate("Approval")}
                        </div>
                        <div className="col py-1">
                            <select className="form-select" name="is_approved" value={data?.is_approved} onChange={e => handleFormInput(e)}>
                                <option value={true}>{cm?.translate("Ad. is approved")}</option>
                                <option value={false}>{cm?.translate("Cancel approval")}</option>
                            </select>
                        </div>
                    </div>
                }
                {/* <Form.Group className="mb-3">
                    <ImageUploader setNewFiles={setNewMedia} setDeletedExistingFile={setDeletedMedia} deletedExistingFile={deletedMedia} existingMediaFiles={data?.media} />
                </Form.Group> */}
                {/* <Form.Group className="mb-4">
                    <Form.Select autoFocus name="category" value={data?.category?.id} onChange={e => handleFormInput(e)}>
                        <option value="">{cm?.translate("Select Category")} *</option>
                        {renderCategories()}
                    </Form.Select>
                </Form.Group> */}

                {/* <Form.Group className="mb-4">
                    <Form.Control type="text" name="title" required placeholder="Title *" value={data?.title || ""} onChange={handleFormInput} />
                </Form.Group> */}
                {/* <Form.Group className="mb-4">
                    <MonozunaEditor name="description" placeholder={cm?.translate("Description *")} content={data?.description} updateHtmlContent={handleHtmlEditorReturn} style={{ height: 150 }} />
                </Form.Group> */}

                {/* <Form.Group className="mb-3">
                    <Form.Select name="privacy_type" defaultValue={data?.privacy?.id || ""} onChange={handleFormInput}>
                        {renderPrivacyTypes()}
                    </Form.Select>
                </Form.Group> */}
                {/* {enableOrderable()}
                {enableShorts()} */}

                {/* <Form.Group className="mb-5 mt-5 d-flex flex-row align-items-center">
                    {enableOrderableButton()}
                    {enableShortsButton()}
                </Form.Group> */}
                {/* {auth?.authUser?.is_superuser &&
                    <Form.Group className="mb-3 font-size-11 d-flex align-items-center">
                        <select className="form-select" name="is_approved" value={data?.is_approved} onChange={e => handleFormInput(e)}>
                            <option value={true}>{cm?.translate("Ad. is approved")}</option>
                            <option value={false}>{cm?.translate("Cancel approval")}</option>
                        </select>
                    </Form.Group>
                } */}
            </Modal.Body>
            <Modal.Footer>
                {<SaveButton handleSave={handleSave} disabled={!enableSave} />}
                <Button className="btn btn-sm btn-secondary" onClick={handleClose}>Cancel</Button>
            </Modal.Footer>
        </Modal >
    </>
}
export default ModifyAd;