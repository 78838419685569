import Swal from "sweetalert2";

const useAlert = () => {
    const show = (message, type) => {
        Swal.fire({
            html: message, icon: type,
            customClass: {
                confirmButton: "btn btn-success",
                cancelButton: "btn btn-secondary ms-2",
            }, buttonsStyling: false
        })
    };

    const showThenRedirect = (message, type, url) => {
        Swal.fire({
            html: message, icon: type,
            customClass: {
                confirmButton: "btn btn-success",
                cancelButton: "btn btn-secondary ms-2",
            }, buttonsStyling: false
        }).then((result) => {
            if (result.isConfirmed) {
                window.location = url;
            }
        })
    };

    return { show, showThenRedirect };
}

export default useAlert;