import { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { BsPostcard } from "react-icons/bs";
import ImageUploader from "../ImageUploader";
import SaveButton from "../SaveButton";
import { useApi } from "../../hooks/useApi"
import { useAuth } from "../../hooks/useAuth"
import { useCommon } from "../../hooks/useCommon";
import useAlert from "../../hooks/useAlert";
import MonozunaEditor from "../MonozunaEditor";
import Orderable from "../modals/ordernow/Orderable";

const CreateAd = ({ buttonWithIcon = true, btnTitle = "Create Ad.", redirectPath = "/ads" }) => {
    const [show, setShow] = useState(false);
    const [mediaFiles, setMediaFiles] = useState([]);
    const [privacyTypes, setPrivacyTypes] = useState([]);
    const [data, setData] = useState({})
    const [selectedCategory, setSelectedCategory] = useState([])
    const [enableSave, setEnableSave] = useState(false)
    const [showOrderable, setShowOrderable] = useState(false)
    const [orderable, setOrderable] = useState({})

    const api = useApi();
    const auth = useAuth();
    const cm = useCommon();
    const alert = useAlert();


    const handleClose = () => {
        setData({});
        setSelectedCategory({});
        setMediaFiles([])
        cm.showLoader(false);
        cm?.setAdsCache({});
        setShow(false);
    }

    const handleShow = () => {
        if (!auth.authUser) {
            window.location = "login"
        } else if (auth.authUser && auth.authUser?.user_profile?.is_approved === false) {
            alert.show("Professional account is not active.<br/><br/>Check <a href='/mypage/profile'>My Page > Profile</a>", "info")
        }
        else {
            setShow(true)
        }
    }

    const handleFormInput = (e) => {
        const key = e.target.name;
        const value = e.target.value;
        setData({ ...data, [key]: value })
        if (key === "category") {
            setSelectedCategory(cm?.categories.find(c => parseInt(c?.id) === parseInt(value)))
        }
    }

    const handleOrderable = (v) => {
        setOrderable(v)
    }

    const handleHtmlEditorReturn = (v) => {
        setData({ ...data, ...v })
    }

    const getPrivacyTypeFromApi = async (params) => {
        try {
            const res = await api.getPrivacyTypes();
            setPrivacyTypes(res);
        } catch (err) {
            alert.show(err?.message, "error");
        }
    };

    const getCategoriesFromApi = async () => {
        try {
            const res = await api.getCategories();
            if (res) {
                cm?.setCategories(res.sort((a, b) => a?.group?.id - b?.group?.id));
            }
        } catch (err) {
            alert.show(err?.message, "error")
        }
    };

    // const renderCategories = () => {
    //     return cm.categories?.map(c => <option key={"cat_" + c.id} value={c.id}>{c.group?.name} - {c.name}</option>)
    // }

    const renderCategories = () => {
        const uniq = (items) => [...new Set(items)];
        const uniqueGroups = uniq(cm?.categories.map((item) => item?.group?.name));
        return uniqueGroups?.map((g, ix) => {
            return <optgroup key={g + "_" + ix} label={g} style={{ background: "#fff" }}>
                {cm?.categories && cm?.categories?.map(c => c?.group?.name === g && <option key={"cat_" + c.id} value={c?.id} >{cm?.translate(c?.name)} ({cm?.translate(c?.total_ads, cm?.language, true)})</option>)}
            </optgroup>
        })
    }

    const renderPrivacyTypes = () => {
        return privacyTypes && privacyTypes?.map(p => <option key={"pt_" + p.id} value={p.id}>{cm?.translate("Allow to " + p.name)}</option>)
    }

    const handleSave = async () => {
        try {
            if (data) {
                cm.showLoader(true, "Saving..");
                const res = await api.createAd(data);
                if (res?.alias_id && Object.keys(orderable).length > 0) {
                    const ord = { ...orderable, ad_alias_id: res?.alias_id }
                    const res2 = await api.createOrderable(ord)
                    if (res2) {
                        handleClose();
                        alert.showThenRedirect("New Ad. was created", "success", redirectPath);
                    }
                } else {
                    handleClose();
                    alert.showThenRedirect("New Ad. was created", "success", redirectPath);
                }
            }
        } catch (err) {
            setData({});
            handleClose();
            alert.show(err?.response?.data?.msg, "error")
            cm.showLoader(false);
        }
    }

    const enableSaveButton = () => {
        if (data?.title && data?.category && data?.description) {
            setEnableSave(true)
        } else {
            setEnableSave(false)
        }
    }

    useEffect(() => {
        if (show) {
            getPrivacyTypeFromApi()
            getCategoriesFromApi()
        }
    }, [show])

    useEffect(() => {
        setData({
            ...data, media: mediaFiles?.map(i => {
                return { base64: i.base64, name: i.name }
            })
        })
    }, [mediaFiles])

    useEffect(() => {
        setData({ ...data })
    }, [privacyTypes])

    useEffect(() => {
        enableSaveButton()
    }, [data])

    // useEffect(() => {
    //     console.log(orderable)
    // }, [orderable])


    return <>
        {buttonWithIcon ? <button onClick={handleShow} className="align-self-stretch btn btn-outline-success rounded-pill border-0 d-flex justify-content-center align-items-center">
            <BsPostcard className="fs-4" />
            <span className="d-inline-block ms-2">{cm?.translate(btnTitle)}</span>
        </button> : <button onClick={handleShow} className="align-self-stretch btn btn-sm btn-outline-success rounded-pill">
            <span className="d-inline-block ms-2">{cm?.translate(btnTitle)}</span>
        </button>}

        <Modal size="lg" scrollable="true" show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>{cm?.translate("Create Ad.")}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-3">
                {/* <Form.Group className="mb-3">
                    <ImageUploader setNewFiles={setMediaFiles} />
                </Form.Group> */}
                <div className="row m-0 mb-1">
                    <div className="col-sm-12 col-md-3  d-flex align-items-center bg-light py-1">
                        {cm?.translate("Photos")}
                    </div>
                    <div className="col py-0">
                        <ImageUploader setNewFiles={setMediaFiles} label={cm?.translate("Upload")} />
                    </div>
                </div>
                <div className="row m-0 mb-1">
                    <div className="col-sm-12 col-md-3  d-flex align-items-center bg-light py-1 position-relative">
                        {cm?.translate("Category")}
                        <span className="font-size-8 text-secondary position-absolute end-0 bottom-0 me-1">{cm?.translate("Required")}</span>
                    </div>
                    <div className="col d-flex align-items-center py-1">
                        <Form.Select autoFocus name="category" onChange={e => handleFormInput(e)}>
                            <option value="">{cm?.translate("Select Category")}</option>
                            {renderCategories()}
                        </Form.Select>
                    </div>
                </div>
                <div className="row m-0 mb-1">
                    <div className="col-sm-12 col-md-3  d-flex align-items-center bg-light py-1 position-relative">
                        {cm?.translate("Title")}
                        <span className="font-size-8 text-secondary position-absolute end-0 bottom-0 me-1">{cm?.translate("Required")}</span>
                    </div>
                    <div className="col d-flex align-items-center py-1">
                        <Form.Control type="text" name="title" placeholder={cm?.translate("Write a suitable title")} required onChange={e => handleFormInput(e)} />
                    </div>
                </div>
                <div className="row m-0 mb-1">
                    <div className="col-sm-12 col-md-3  d-flex align-items-start bg-light py-1 position-relative">
                        {cm?.translate("Description")}
                        <span className="font-size-8 text-secondary position-absolute end-0 bottom-0 me-1">{cm?.translate("Required")}</span>
                    </div>
                    <div className="col py-1">
                        <MonozunaEditor name="description" placeholder={cm?.translate("Write or paste item detail")} updateHtmlContent={handleHtmlEditorReturn} style={{ height: 200 }} />
                    </div>
                </div>
                <div className="row m-0 mb-1">
                    <div className="col-sm-12 col-md-3 d-flex align-items-center bg-light py-1">
                        {cm?.translate("Privacy")}
                    </div>
                    <div className="col py-1">
                        <Form.Select name="privacy_type" onChange={e => handleFormInput(e)}>
                            {renderPrivacyTypes()}
                        </Form.Select>
                    </div>
                </div>
                {/* <Form.Group className="mb-3">
                    <Form.Select autoFocus name="category" onChange={e => handleFormInput(e)}>
                        <option value="">{cm?.translate("Select Category")} *</option>
                        {renderCategories()}
                    </Form.Select>
                </Form.Group> */}

                {/* <Form.Group className="mb-3">
                    <Form.Control type="text" name="title" required placeholder={(cm?.translate("Title")) + " *"} onChange={e => handleFormInput(e)} />
                </Form.Group> */}

                {/* <Form.Group className="mb-3">
                    <MonozunaEditor name="description" placeholder={cm?.translate("Description *")} updateHtmlContent={handleHtmlEditorReturn} style={{ height: 200 }} />
                </Form.Group> */}

                {/* <Form.Group className="mb-4">
                    <Form.Select name="privacy_type" onChange={e => handleFormInput(e)}>
                        {renderPrivacyTypes()}
                    </Form.Select>
                </Form.Group> */}

                {/* {selectedCategory?.group?.id === 1 &&
                    <Form.Group className="my-4">
                        <button className="btn btn-white border border-opacity-10" onClick={e => setShowOrderable(true)}><img src={process.env.PUBLIC_URL + "/static/orderable.png"} width={100} alt="orderable" /></button>
                        {showOrderable && <Orderable existingOrderOption={data?.order_option} setShowOrderable={setShowOrderable} handleOrderable={handleOrderable} />}
                    </Form.Group>
                } */}
            </Modal.Body>
            <Modal.Footer>
                {<SaveButton handleSave={handleSave} disabled={!enableSave} />}
                <Button className="btn btn-sm btn-secondary" onClick={handleClose}>{cm?.translate("Cancel")}</Button>
            </Modal.Footer>
        </Modal >
    </>
}
export default CreateAd;